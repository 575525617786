/**
 *
 * @param {HTMLElement} collapseHeader
 */
export function toggleCollapse(collapseHeader) {
  document.body.classList.toggle("header-collapse");
  if (collapseHeader) {
    collapseHeader.classList.toggle("no-header");
    collapseHeader.classList.toggle("active");
  }
}
