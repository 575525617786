import { devotionTypes } from "@/constants/enum";
import { useDevotionStore } from "@/store/devotionStore";
import { useWordStore } from "@/store/wordStore";

const getMonthAndYear = (dateText) => {
  const date = new Date(dateText);
  const month = date.getMonth();
  const year = date.getFullYear();
  return { month, year };
};
export async function reloadDevotions(type, date) {
  // wait for 500 ms so update would have been processed
  setTimeout(async () => {
    if (type == devotionTypes.dailyDevotion) {
      await useDevotionStore().fetchDevotionByMonth(getMonthAndYear(date));
    } else {
      await useWordStore().fetchWordsByMonth(getMonthAndYear(date));
    }
  }, 500);
}
