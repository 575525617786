<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>
  <div class="page-wrapper">
    <div class="content">
      <!-- <GymNavigationButtons /> -->
      <div class="page-header">
        <div class="add-item d-flex">
          <div class="page-title">
            <h4>Product Category List</h4>
            <h6>Manage Your Categories</h6>
          </div>
        </div>
        <ul class="table-top-head">
          <!-- <li>
            <a data-bs-toggle="tooltip" data-bs-placement="top" title="Refresh"
              ><i data-feather="rotate-ccw" class="feather-rotate-ccw"></i
            ></a>
          </li> -->
          <li>
            <a
              ref="collapseHeader"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Collapse"
              @click="toggleCollapse(collapseHeader)"
            >
              <i data-feather="chevron-up" class="feather-chevron-up"></i>
            </a>
          </li>
        </ul>
        <div class="page-btn">
          <a
            href="javascript:void(0);"
            class="btn btn-added"
            @click="openModal('new')"
            ><vue-feather type="plus-circle" class="me-2"></vue-feather>Add To List
          </a>
        </div>
      </div>
      <!-- USER TABLE -->
       <!-- {{ ProductList.data }} + -->
      <filter-table
        :extra-class="['table', 'datanew']"
        :columns="CategoryList.columns"
        :data="CategoryList.data"
        :filters="CategoryList.filters"
      >
        <template #header>
          <h4>Available Products</h4>
        </template>
        <template #bodyCell="{ column, record }">
          <!-- <template v-if="column.key === 'category'">{{record.category.name}}</template>
          <template v-else-if="column.key === 'status'">
            <a-switch
              :checked="record.is_active"
              @change="updateProductStatus($event, record.id)"
            />
          </template> -->
          <template v-if="column.key === 'action'">
            <td class="action-table-data">
              <div class="edit-delete-action">
                <a @click="openModal('update', record.id)" class="me-2 p-2 mb-0">
                  <i data-feather="edit" class="feather-edit"></i>
                </a>
                <a
                  @click="deleteCategory(record.id)"
                  class="me-2 confirm-text p-2 mb-0"
                  href="javascript:void(0);"
                >
                  <i data-feather="trash-2" class="feather-trash-2"></i>
                </a>
              </div>
            </td>
          </template>
        </template>
      </filter-table>
      <!-- USER TABLE -->
    </div>
  </div>
  <CategoryModal :category-id="selectedCategoryId" />
</template>

<script setup>
import GymNavigationButtons from '@/views/layouts/gym-setting.vue';
import CategoryModal from "./modal/category-list-modal.vue";
import { computed, onMounted, ref } from "vue";
import Swal from "sweetalert2";
import { useProductStore } from '@/store/productStore';
import { formatAmount } from "@/utils";
import { confirm } from "@/utils/swal";
import { toggleCollapse } from "@/utils/components";
import { plans } from "@/constants/enum";
import { useRouter } from "vue-router";
// store
const categoryStore = useProductStore();
const router = useRouter();
// data
/**
 * Id of Plan that is being edited
 */
const selectedCategoryId = ref(null);
const CategoryList = ref({
  filters: ["name"],
  columns: [
    {
      title: "Category Name",
      dataIndex: "name",
      sorter: {
        compare: (a, b) => {
          a = a.name.toLowerCase();
          b = b.name.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      sorter: true,
    },
    {
      title: "Action",
      key: "action",
      sorter: true,
    },
  ],
  data: computed(() => categoryStore.categories),
});

const canAddNewPlan = computed(() => categoryStore.products.length < 3);
// ref elements
const collapseHeader = ref(null);
// Methods
async function deleteCategory(id) {
  confirm({
    title: "Are you sure about this?",
    message: "All the products under this category will be deleted.",
    callback: async (continueDelete) => {
      if (continueDelete) {
        await categoryStore.deleteCategory(id);
      }
    },
  });
}

function openModal(action, id = null) {
  if (action == "update") {
    selectedCategoryId.value = id;
  } else {
    selectedCategoryId.value = null;
  }
  $("#category-modal").modal("show");
}

async function updateProductStatus(isActive, userId) {
  await categoryStore.updateProductStatus(userId, { status: isActive ? "active" : "inactive" });
  await categoryStore.fetchProducts();
}

onMounted(async () => {
  if (CategoryList.value.data.length == 0) {
    await categoryStore.fetchCategories();
  }
});
</script>


