<template>
  <!-- Add User -->
  <Modal title="Admin" ref="adminModal" @opened="initData">
    <div id="userDetail">
      <div class="row">
        <!-- <div class="col-lg-12 d-flex justify-content-center mb-5">
          <ImageUpload
            class="rounded-circle"
            v-model:file="admin.profile_picture"
            :src="admin.profile_picture_preview"
          />
          <Avatar
            img-class="user-image"
            class="rounded-circle"
            v-model:file="admin.profile_picture"
            :src="admin.profile_picture_preview"
          />
        </div> -->
        <div class="col-lg-6">
          <div class="input-blocks">
            <label>First Name</label>
            <input
              type="text"
              class="form-control"
              required
              name="first_name"
              :disabled="action == actions.VIEW"
              v-model="admin.first_name"
            />
          </div>
        </div>
        <div class="col-lg-6">
          <div class="input-blocks">
            <label>Last Name</label>
            <input
              type="text"
              class="form-control"
              required
              name="last_name"
              :disabled="action == actions.VIEW"
              v-model="admin.last_name"
            />
          </div>
        </div>
        <!-- <div class="col-lg-6">
          <div class="input-blocks">
            <label>Account Type</label>
            <vue-select :options="accountTypes" id="listus" placeholder="Choose" :disabled="action != actions.CREATE"
              v-model="admin.account_type" required name="accountType" />
          </div>
        </div>
        <div class="col-lg-6">
          <div class="input-blocks">
            <label>Dail Code</label>
            <div class="d-flex">
              <vue-select class="w-100" id="dailCodes" :options="dailCodes" required name="dail_code"
                :disabled="action == actions.VIEW" v-model="admin.dail_code" />
            </div>
          </div>
        </div> -->
        <div class="col-lg-6">
          <div class="input-blocks">
            <label>Mobile Number</label>
            <input
              type="number"
              :minlength="9"
              class="form-control"
              required
              name="mobile_number"
              :disabled="action == actions.VIEW"
              v-model="admin.phone"
            />
          </div>
        </div>

        <div class="col-lg-6">
          <div class="input-blocks">
            <label>Email</label>
            <input
              type="email"
              class="form-control"
              required
              name="email"
              :disabled="action == actions.VIEW"
              v-model="admin.email"
            />
          </div>
        </div>

        <div class="col-lg-6" :hidden="action !== actions.UPDATE">
          <div class="input-blocks">
            <label>Password</label>
            <input
              type="password"
              class="form-control"
              required
              name="password"
              v-model="admin.password"
            />
          </div>
        </div>

        <!-- <div v-if="action == actions.VIEW">
          <h4 class="mt-5 mb-3">
            Subscription Detail
          </h4>
          <div class="col-lg-12">
            <div class="input-blocks">
              <label>Current Subscription</label>
              <input class="form-control" disabled :value="admin.subscription.name || 'None'" />
            </div>
          </div>

          <div class="row col-12 px-0 mx-0" v-if="admin.subscription.name">
            <div class="col-lg-6">
              <div class="input-blocks">
                <label>Status</label>
                <input class="form-control" disabled :value="admin.subscription.status || 'None'" />
              </div>
            </div>
            <div class="col-lg-6">
              <div class="input-blocks">
                <label>Auto Renews</label>
                <input class="form-control" disabled v-model="admin.subscription.auto_renews" />
              </div>
            </div>
            <div class="col-lg-6">
              <div class="input-blocks">
                <label>Created At</label>
                <input class="form-control" disabled :value="formatDate(admin.subscription.created_at)" />
              </div>
            </div>
            <div class="col-lg-6">
              <div class="input-blocks">
                <label>Expires At</label>
                <input class="form-control" disabled :value="formatDate(admin.subscription.expires_at)" />
              </div>
            </div>
          </div>

        </div> -->
      </div>
      <div class="modal-footer-btn" :hidden="action == actions.VIEW">
        <button
          type="button"
          class="btn btn-cancel me-2"
          @click="adminModal.closeModal()"
        >
          Cancel
        </button>
        <button type="submit" class="btn btn-submit" id="submit" @click="saveUser()">
          Submit
        </button>
      </div>
    </div>
  </Modal>
  <!-- /Add User -->
</template>
<script setup>
import countries from "@/assets/json/country_codes.json";
import { computed, onMounted, ref, watch } from "vue";
import ImageUpload from "../components/image-upload.vue";
import { useAdminStore } from "@/store/adminStore";
import { checkInputValidity } from "@/utils/validator";
import Modal from "@/components/modal/modal.vue";
import { formatDate } from "@/utils";

// stores
const adminStore = useAdminStore();

// refs
const adminModal = ref(null);
const actions = {
  VIEW: "View",
  CREATE: "Create",
  UPDATE: "Update",
};

// props
const props = defineProps({
  adminId: String,
  action: {
    type: String,
    required: true,
  },
});

// computed
const isUpdate = computed(() => props.userId);
const showPassword = ref(false);
const dailCodes = computed(() => {
  return countries.map((country) => ({
    text: `${country.dail_code} ${country.name}`,
    id: country.dail_code,
  }));
});

const admin = ref({
  email: null,
  name: "",
  first_name: null,
  last_name: null,
  // dail_code: null,
  phone: null,
  // account_type: null,
  // is_admin: null,
  password: null,
  profile_picture: null,
  profile_picture_preview: null,
  subscription: {},
});

watch(
  admin,
  () => {
    setTimeout(() => checkInputValidity("submit", "userDetail"), 5);
  },
  { deep: true }
);

watch(
  () => props.adminId,
  () => {
    if (props.adminId) {
      fillData({ ...adminStore.admins.find((admin) => admin.id == props.adminId) });
    } else {
      resetData();
    }
  },
  { immediate: true }
);

const accountTypes = ["Subscriber", "Administrator"];

async function saveUser() {
  let response = false;

  const data = {
    email: admin.value.email,
    name: admin.value.first_name + " " + admin.value.last_name,
    // dail_code: admin.value.dail_code,
    mobile_number: admin.value.phone,
    // is_admin: admin.value.account_type == 'Administrator' ? 1 : 0,
    // profile_picture: admin.value.profile_picture,
    password: admin.value.password,
  };

  if (props.action == actions.UPDATE) {
    response = await adminStore.updateAdminDetails(props.adminId, data);
    if (response) {
      adminModal.value.closeModal();
    }
  } else {
    response = await adminStore.addAdmin(data);
  }

  response && adminModal.value.closeModal();
}

function resetData() {
  admin.value.email = null;
  admin.value.first_name = null;
  admin.value.last_name = null;
  admin.value.dail_code = null;
  admin.value.phone = null;
  admin.value.account_type = null;
  admin.value.is_admin = null;
  admin.value.password = null;
  admin.value.profile_picture = null;
  admin.value.profile_picture_preview = null;
  admin.value.subscription = {};
}
function fillData(data) {
  admin.value = data;
  admin.value.account_type = data.is_admin ? "Administrator" : "Subscriber";
  [admin.value.first_name, admin.value.last_name] = data.name.split(" ");
  admin.value.profile_picture_preview = data.profile_picture;
}

function initData() {
  if (!props.userId) {
    resetData();
  }
  setTimeout(() => checkInputValidity("submit", "userDetail"), 5);
}
onMounted(() => {});

// Expose methods
defineExpose({ openModal: () => adminModal.value.openModal() });
</script>
