<template>
  <!-- Add Plan -->
  <div class="modal fade" id="vendor-modal">
    <div class="modal-dialog modal-dialog-centered custom-modal-two">
      <div class="modal-content">
        <div class="page-wrapper-new p-0">
          <div class="content">
            <div class="modal-header border-0 custom-modal-header">
              <div class="page-title">
                <h4>Vendor</h4>
              </div>
              <button
                type="button"
                class="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body custom-modal-body">
              <form @submit.prevent>
                <!-- <form @submit.prevent="props.vendorId ? addOrUpdate : submitForm"> -->
                <div class="row">
                  <div class="col-lg-12">
                    <div class="input-blocks">
                      <label>Vendor Name</label>
                      <input
                        type="text"
                        name="name"
                        required
                        v-model="vendor.name"
                        :class="{ 'form-control': true }"
                      />
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="input-blocks">
                      <label>Vendor Email</label>
                      <input
                        type="email"
                        name="email"
                        required
                        v-model="vendor.email"
                        :class="{ 'form-control': true }"
                      />
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="input-blocks">
                      <label>Vendor Mobile Number</label>
                      <input
                        type="number"
                        name="mobile_number"
                        required
                        v-model="vendor.mobile_number"
                        :class="{ 'form-control': true }"
                      />
                    </div>
                  </div>

                  <div class="col-lg-12" v-if="vendor.otpSent || response.data.otpSent">
                    <div class="input-blocks">
                      <label>One Time Password</label>
                      <input
                        type="text"
                        name="otps"
                        required
                        v-model="vendor.otp"
                        :class="{ 'form-control': true }"
                      />
                    </div>
                  </div>
                </div>
                <div class="modal-footer-btn">
                  <button
                    type="button"
                    class="btn btn-cancel me-2"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    class="btn btn-submit"
                    id="submit"
                    v-if="props.vendorId"
                    @click="addOrUpdate"
                  >
                    Submit
                  </button>
                  <button
                    type="submit"
                    class="btn btn-submit"
                    id="submit"
                    @click="submitForm"
                    v-else
                  >
                    {{ vendor.otpSent ? "Submit" : "Get OTP" }}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Plan -->
</template>
<script setup>
import { checkInputValidity } from "@/utils/validator";
import { computed, onMounted, reactive, ref, watch } from "vue";
import { useProductStore } from "@/store/productStore";
// stores
const vendorStore = useProductStore();
const response = ref({
  data: {
    otpSent: null,
  },
});
const props = defineProps({
  vendorId: null,
});
// data
const vendor = reactive({
  name: "",
  email: "",
  mobile_number: "",
  otp: "",
  otpSent: false,
});

async function addOrUpdate() {
  try {
    if (props.vendorId) {
      // Update existing vendor
      if (response.value.data.otpSent) {
        response.value = await vendorStore.verifyUpdateVendorOtp(props.vendorId, vendor);
        vendor.otpSent = false;
      } else {
        response.value = await vendorStore.updateVendor(props.vendorId, vendor);
      }
    } else {
      // Add new vendor
      response.value = await vendorStore.addVendor(vendor);
    }

    if (
      response.value &&
      response.value.status === true &&
      response.value.data.otpSent === false
    ) {
      // Hide modal if successful
      $("#vendor-modal").modal("hide");

      // Reset the vendor form fields
      resetVendorForm();
    }
  } catch (error) {
    console.error("An error occurred during form submission:", error);
  }
}

async function sendOtp() {
  const status = await vendorStore.sendVendorOtp(vendor);
  if (status) {
    vendor.otpSent = true;
    Swal.fire("One Time Password Sent To Vendor's Email", "", "success");
  }
}

async function submitForm() {
  if (vendor.otpSent) {
    await addOrUpdate();
  } else {
    await sendOtp();
  }
}

watch(
  () => vendor,
  () => {
    // setTimeout is used here so the service will be passed to the input b4 this runs
    setTimeout(() => checkInputValidity("submit"), 5);
  },
  { deep: true }
);

watch(
  () => props.vendorId,
  (newVendorId) => {
    if (props.vendorId) {
      const existingVendor = vendorStore.vendors.find(
        (vendor) => vendor.id == newVendorId
      );
      if (existingVendor) {
        Object.assign(vendor, existingVendor);
      }
    } else {
      vendor.name = "";
      vendor.email = "";
      vendor.mobile_number = "";
    }
  },
  { immediate: true }
);

onMounted(() => {});
</script>
