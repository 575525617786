import { api } from "@/configs/index";
import { defineStore } from "pinia";
import { apiUrls } from "@/constants";

export const useTransactionStore = defineStore("transaction", {
  state: () => ({
    transactions: [],
  }),
  actions: {
    async fetchTransactions() {
      try {
        const response = await api.get(apiUrls.transactions);
        this.transactions = response.data.data;
      } catch (error) {
        console.error(error);
      }
    },
    
  },
  mutations: {
    setTransactions(state, transactions) {
      state.transactions = transactions;
    },
  },
  getters: {
    totalTransactionsAmount(state) {
      return state.transactions.reduce(
        (acc, transaction) => acc + transaction.amount,
        0
      );
    },
  },
});
