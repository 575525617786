<template>
    <!-- <header class="header">
        <h1>Website Updates</h1>
        <p>Stay up-to-date with the latest changes and improvements</p>
    </header> -->
    <main class="updates-container">
        <div class="position-absolute d-flex justify-content-center top-0 w-100">
            <div @click="collapse()" class="collapse-btn">
                <i class="icon-arrow-down d-block"></i>
            </div>
        </div>
        <div class="content row-cols-2 ">
            <UpdateCard v-for="update in updates" :update="update" />
        </div>

    </main>
</template>

<script setup>
import UpdateCard from './update-card.vue';
import { useMainStore } from "@/store/mainStore";
import { computed } from "vue";

const mainStore = useMainStore();
const updates = computed(() => mainStore.updates)
// [
//     {
//         title: "Devotional Image Enlarge",
//         message: "We’ve add a zoom icon on the Image Upload Card, Clicking on it will zoom the Image for a better preview",
//         date: "May 17, 2024"
//     }
// ]

function getCurrentYear() {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    return currentYear;
}

function collapse() {
    mainStore.showUpdates = false;
}
</script>
<style scoped>
.collapse-btn {
    padding: 10px 20px;
    border-radius: 0 0 20px 20px;
    background: var(--PRIMARY-COLOR);
    color: #fff;
    font-size: 20px;
    font-weight: bolder;
    cursor: pointer;
    width: fit-content;
    margin: 0 auto;
}

.updates-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 1050;
    background: #fff;
    animation: slideIn 0.3s;
}

.content {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    margin-top: 80px !important;
}

@keyframes slideIn {
    from {
        transform: translateY(150vh);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}
</style>