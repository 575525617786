import { api } from "@/configs";
import { defineStore } from "pinia";
import { apiUrls } from "@/constants";
import Swal from "sweetalert2";
import { devotionTypes } from "@/constants/enum";

export const useWordStore = defineStore("word", {
  state: () => {
    return {
      monthlyWords: {},
    };
  },
  getters: {
    /**
     *
     * @returns {Array}
     */
    loadWordByMonth: (state) => (year, month) => {
      const date = new Date(year, month).getTime();
      return state.monthlyWords[date] ?? [];
    },
  },
  actions: {
    async addWord(word = { title }) {
      try {
        const response = await api.post(apiUrls.word, word);
        await this.fetchWord();
        Swal.fire("Word of Day Added", "", "success");
        return true;
      } catch (error) {
        return false;
      }
    },
    async fetchWordsByMonth({ year, month }) {
      try {
        const params = {
          type: devotionTypes.word,
          year,
          month: Number.parseInt(month) + 1,
        };
        const response = await api.get(apiUrls.devotion, {
          params: params,
        });

        // note: Timestamp of the Year and Month is used to Identify the devotion of a month from the
        const timestamp = new Date(year, month).getTime();
        this.monthlyWords[timestamp] = response.data.data;
        return true;
      } catch (error) {
        return false;
      }
    },
  },
});
