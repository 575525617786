<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>
  <div class="page-wrapper">
    <div class="content">
      <!-- <GymNavigationButtons /> -->
      <div class="page-header">
        <div class="add-item d-flex">
          <div class="page-title">
            <h4>Voting Category List</h4>
            <!-- <h6>Manage Your Products</h6> -->
          </div>
        </div>
        <ul class="table-top-head">
          <!-- <li>
            <a data-bs-toggle="tooltip" data-bs-placement="top" title="Refresh"
              ><i data-feather="rotate-ccw" class="feather-rotate-ccw"></i
            ></a>
          </li> -->
          <li>
            <a
              ref="collapseHeader"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Collapse"
              @click="toggleCollapse(collapseHeader)"
            >
              <i data-feather="chevron-up" class="feather-chevron-up"></i>
            </a>
          </li>
        </ul>
        <div class="page-btn">
          <a href="javascript:void(0);" class="btn btn-added" @click="openModal('new')"
            ><vue-feather type="plus-circle" class="me-2"></vue-feather>Add To List
          </a>
        </div>
      </div>
      <!-- USER TABLE -->
      <!-- {{ ProductList.data }} + -->
      <filter-table
        :extra-class="['table', 'datanew']"
        :columns="Categories.columns"
        :data="Categories.data"
        :filters="Categories.filters"
      >
        <template #header>
          <!-- <h4>Available Products</h4> -->
        </template>
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'category'">{{ record.category.name }}</template>
          <template v-if="column.key === 'available_quantity'">
            <a href="#" @click="openRestockHistory(record.id)">{{
              record.available_quantity
            }}</a>
          </template>
          <template v-else-if="column.key === 'status'">
            <a-switch
              :checked="record.is_active"
              @change="updateCategoryStatus(record.id)"
            />
          </template>
          <template v-if="column.key === 'action'">
            <td class="action-table-data">
              <div class="edit-delete-action">
                <a @click="openModal('update', record.id)" class="me-2 p-2 mb-0">
                  <i data-feather="edit" class="feather-edit"></i>
                </a>
                <a
                  @click="deleteVotingCategory(record.id)"
                  class="me-2 confirm-text p-2 mb-0"
                  href="javascript:void(0);"
                >
                  <i data-feather="trash-2" class="feather-trash-2"></i>
                </a>
              </div>
            </td>
          </template>
        </template>
      </filter-table>
      <!-- USER TABLE -->
    </div>
  </div>
  <VotingCategoryModal :voting-category-id="selectedCategoryId" />
</template>

<script setup>
import VotingCategoryModal from "./modal/voting-category-list-modal.vue";
import { computed, onMounted, ref } from "vue";
import { useVotingStore } from "@/store/VotingStore";
import { confirm } from "@/utils/swal";
import { toggleCollapse } from "@/utils/components";
// store
const votingStore = useVotingStore();
// data
/**
 * Id of Plan that is being edited
 */
const selectedCategoryId = ref(null);
const selectedProductQuantity = ref(null);
const Categories = ref({
  filters: ["title"],
  columns: [
    {
      title: "Name",
      dataIndex: "name",
      sorter: {
        compare: (a, b) => {
          a = a.name.toLowerCase();
          b = b.name.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Required Gender",
      dataIndex: "required_gender",
      sorter: {
        compare: (a, b) => {
          a = a.required_gender.toLowerCase();
          b = b.required_gender.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Voting starts",
      dataIndex: "start_day",
      sorter: {
        compare: (a, b) => {
          a = new Date(a.start_day);
          b = new Date(b.start_day);
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Voting Ends",
      dataIndex: "last_day",
      sorter: {
        compare: (a, b) => {
          a = new Date(a.last_day);
          b = new Date(b.last_day);
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Status",
      key: "status",
      sorter: true,
    },
    {
      title: "Action",
      key: "action",
      sorter: true,
    },
  ],
  data: computed(() => votingStore.votingCategories),
});

const canAddNewPlan = computed(() => adminStore.products.length < 3);
// ref elements
const collapseHeader = ref(null);
// Methods
async function deleteVotingCategory(id) {
  confirm({
    title: "Are you sure about this?",
    callback: async (continueDelete) => {
      if (continueDelete) {
        await votingStore.deleteVotingCategory(id);
      }
    },
  });
}

function openModal(action, id = null) {
  if (action == "update") {
    selectedCategoryId.value = id;
  } else {
    selectedCategoryId.value = null;
  }
  $("#voting-category-modal").modal("show");
}

function restockModal(productId, quantity) {
  selectedProductId.value = productId;
  selectedProductQuantity.value = quantity;
  $("#restock-modal").modal("show");
}
function openRestockHistory(productId) {
  selectedProductId.value = productId;
  adminStore.fetchRestockHistories(productId);
  $("#restock-history-modal").modal("show");
}

async function updateCategoryStatus(categoryId) {
  await votingStore.updateCategoryStatus(categoryId);
  // await adminStore.fetchSplashScreen();
}

onMounted(async () => {
  if (Categories.value.data.length == 0) {
    await votingStore.fetchVotingCategories();
  }
});
</script>
