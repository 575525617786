<template>
  <!-- Add user -->
  <div class="modal fade" id="password-modal">
    <div class="modal-dialog modal-dialog-centered custom-modal-two">
      <div class="modal-content">
        <div class="page-wrapper-new p-0">
          <div class="content">
            <div class="modal-header border-0 custom-modal-header">
              <div class="page-title">
                <h4>Change Password</h4>
              </div>
              <button
                type="button"
                class="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body custom-modal-body">
              <form @submit.prevent="javascript">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="input-blocks">
                      <label>Current Password</label>
                      <div class="incont">
                      <input
                        :type="showPassword ? 'text' : 'password'"
                        name="name"
                        required
                        v-model="password.current_password"
                        :class="{ 'form-control': true }"
                        id="pass_input"
                      />
                      <span @click="toggleShow" class="toggle-password">
                        <i
                          :class="{
                            'fas fa-eye': showPassword,
                            'fas fa-eye-slash': !showPassword,
                          }"
                        ></i>
                      </span>
                    </div></div>
                  </div>
                  <div class="col-lg-12">
                    <div class="input-blocks">
                      <label>New Password</label>
                      <div class="incont">
                      <input
                        :type="showPassword ? 'text' : 'password'"
                        name="name"
                        required
                        v-model="password.new_password"
                        :class="{ 'form-control': true }"
                         id="pass_input"
                      />
                      <span @click="toggleShow" class="toggle-password">
                        <i
                          :class="{
                            'fas fa-eye': showPassword,
                            'fas fa-eye-slash': !showPassword,
                          }"
                        ></i>
                      </span>
                    </div>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="input-blocks">
                      <label>Confirm Password</label>
                      <div class="incont">
                      <input
                         :type="showPassword ? 'text' : 'password'"
                        name="amount"
                        required
                        v-model="password.confirm_password"
                        :class="{ 'form-control': true }"
                         id="pass_input"
                      />
                      <span @click="toggleShow" class="toggle-password">
                        <i
                          :class="{
                            'fas fa-eye': showPassword,
                            'fas fa-eye-slash': !showPassword,
                          }"
                        ></i>
                      </span>
                    </div>
                  </div></div>
                </div>
                <div class="modal-footer-btn">
                  <button
                    type="button"
                    class="btn btn-cancel me-2"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>

                  <button
                    type="submit"
                    class="btn btn-submit"
                    id="submit"
                    @click="submitForm"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Plan -->
</template>
<script setup>
import { useAdminStore } from "@/store/adminStore";
import { checkInputValidity } from "@/utils/validator";
import Swal from "sweetalert2";
import { computed, onMounted, reactive, ref, watch } from "vue";
// stores
const adminStore = useAdminStore();
// data
const password = ref({
  new_password: null,
  confirm_password: null,
  current_password: null,
});

const showPassword = ref(false);

function toggleShow() {
  showPassword.value = !showPassword.value;
}

async function submitForm() {
  if (password.value.new_password !== password.value.confirm_password) {
    Swal.fire("Passwords Not Matching", "", "error");
  } else {
    const status = await adminStore.changePassword(
      password.value.new_password,
      password.value.current_password
    );
    if (status) {
      $("#credit-modal").modal("hide");
      Swal.fire("Password Changed", "", "success");
    }
  }
}
watch(
  [() => password],
  () => {
    // setTimeout is used here so the user will be passed to the input b4 this runs
    setTimeout(() => checkInputValidity("submit", "password-modal"), 5);
  },
  { deep: true }
);

// watch(
//   () => props.selected_account_number,
//   () => {
//     if (props.selected_account_number) {
//       user.value = adminStore.users.find(
//         (user) => user.account_number == props.selected_account_number
//       );
//     } else {
//       user.value = {};
//     }
//   },
//   { immediate: true }
// );

onMounted(() => {});
</script>
<style scoped>
.incont{width:100%}
.incont input{width:50%;margin-right:2%;display: inline-block;}
.incont .toggle-password{width:5%;margin-left:2%;display: inline-block;}
#pass_input{width:90%}
</style>