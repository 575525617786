<template>
    <!-- Add Plan -->
    <div class="modal fade" id="devotionCatModal">
        <div class="modal-dialog modal-dialog-centered custom-modal-two">
            <div class="modal-content">
                <div class="page-wrapper-new p-0">
                    <div class="content">
                        <div class="modal-header border-0 custom-modal-header">
                            <div class="page-title">
                                <h4>Devotion Category</h4>
                            </div>
                            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body custom-modal-body">
                            <form @submit.prevent="submitForm">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="input-blocks">
                                            <label>Category Name</label>
                                            <input type="text" name="name" required v-model="devotionCategory.name"
                                                :class="{ 'form-control': true }" />
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="input-blocks">
                                            <label>Description</label>
                                            <input type="text" name="description" required v-model="devotionCategory.description"
                                                :class="{ 'form-control': true }" />

                                        </div>
                                    </div>

                                    <!-- <div class="col-lg-6">
                                        <div class="input-blocks">
                                            <label>Duration (Months)</label>
                                            <input :min="0" type="number" name="months" required v-model="plan.months"
                                                :class="{ 'form-control': true }" />
                                        </div>
                                    </div> -->

                                </div>
                                <div class="modal-footer-btn">
                                    <button type="button" class="btn btn-cancel me-2" data-bs-dismiss="modal">
                                        Cancel
                                    </button>
                                    <button type="submit" class="btn btn-submit" id="submit">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- /Add Plan -->


</template>
<script setup>
import { useDevotionStore } from '@/store/devotionStore';
import { usePlanStore } from '@/store/planStore';
import { checkInputValidity } from '@/utils/validator';
import { computed, onMounted, reactive, ref, watch } from 'vue';
// stores
const devotionStore = useDevotionStore();

const props = defineProps({
    categoryId: null
});
// data
const devotionCategory = ref({
    name: '',
    description: null,
});


async function submitForm() {
    if (props.categoryId) {
        // update
        await devotionStore.updateDevotionCategory(props.categoryId, devotionCategory.value);
    } else {
        // add
        await devotionStore.addDevotionCategory(devotionCategory.value);
    }
    $("#devotionCatModal").modal("hide");
}

watch(() => devotionCategory, () => {
    // setTimeout is used here so the plan will be passed to the input b4 this runs
    setTimeout(() => (
        checkInputValidity("submit")), 5)
}, { deep: true });

watch(() => props.categoryId, () => {
    if (props.categoryId) {
        devotionCategory.value = devotionStore.devotionCategories.find(devotionCategory => devotionCategory.id == props.categoryId);
    } else {
        devotionCategory.value = {};
    }
}, { immediate: true });

onMounted(() => {

});
</script>
