<template>
  <!-- Add user -->
  <div class="modal fade" id="credit-modal">
    <div class="modal-dialog modal-dialog-centered custom-modal-two">
      <div class="modal-content">
        <div class="page-wrapper-new p-0">
          <div class="content">
            <div class="modal-header border-0 custom-modal-header">
              <div class="page-title">
                <h4>Credit User</h4>
              </div>
              <button
                type="button"
                class="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body custom-modal-body">
              <form @submit.prevent="javascript">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="input-blocks">
                      <label>Account Number</label>
                      <input
                        type="number"
                        name="name"
                        required
                        :disabled="props.action != null"
                        v-model="user.account_number"
                        :class="{ 'form-control': true }"
                        @input="search(user.account_number)"
                      />
                      <i style="color: green">{{ searchedAccount }}</i>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="input-blocks">
                      <label>Amount</label>
                      <input
                        type="number"
                        name="amount"
                        required
                        v-model="user.amount"
                        :class="{ 'form-control': true }"
                      />
                    </div>
                  </div>

                  <div class="col-lg-12" v-if="otpSent">
                    <div class="input-blocks">
                      <label>One Time Password</label>
                      <input
                        type="text"
                        name="otps"
                        required
                        v-model="otp.otp"
                        :class="{ 'form-control': true }"
                      />
                    </div>
                  </div>

                  <!-- <div class="col-lg-6">
                    <div class="input-blocks">
                      <label>Duration (Months)</label>
                      <input
                        :min="0"
                        type="number"
                        name="months"
                        required
                        v-model="plan.months"
                        :class="{ 'form-control': true }"
                      />
                    </div>
                  </div> -->
                </div>
                <div class="modal-footer-btn">
                  <button
                    type="button"
                    class="btn btn-cancel me-2"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>

                  <button
                    type="submit"
                    class="btn btn-submit"
                    id="submit"
                    @click="handleSubmit()"
                  >
                  <!-- Fund User -->
                    {{ otpSent ? "Fund User" : "Get OTP" }}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Plan -->
</template>
<script setup>
import { useUserStore } from "@/store/userStore";
import { checkInputValidity } from "@/utils/validator";
import Swal from "sweetalert2";
import { computed, onMounted, reactive, ref, watch } from "vue";
// stores
const userStore = useUserStore();

const searchedAccount = ref(null);
const otpSent = ref(false);
const otp = ref({
  otp: null,
});
const props = defineProps({
  selected_account_number: null,
  action: null,
});
// data
const user = ref({
  account_number: null,
  amount: null,
});

async function submitForm() {
  const status = await userStore.creditAccount(otp.value, user.value.account_number);
  if (status) {
    $("#credit-modal").modal("hide");
    otpSent.value = false;
    user.value = {};
    otp.value = {};
    Swal.fire("Done", "", "success");
  }
}
async function sendOtp() {
  const status = await userStore.sendFundUserOtp(
    user.value.account_number,
    user.value.amount
  );
  if (status) {
    // $("#credit-modal").modal("hide");
    otpSent.value = true;
    // Swal.fire("Done", "", "success");
    Swal.fire("OTP sent", "", "success");
  }
}

function cancel() {
  otpSent.value = false;
  user.value = {};
}


function handleSubmit() {
  if (otpSent.value) {
    submitForm();
  } else {
    sendOtp();
  }
}

watch(
  [() => user, () => otp, () => otpSent],
  () => {
    // setTimeout is used here so the user will be passed to the input b4 this runs
    setTimeout(() => checkInputValidity("submit", "credit-modal"), 5);
  },
  { deep: true }
);

watch(
  () => props.selected_account_number,
  () => {
    if (props.selected_account_number) {
      user.value = userStore.users.find(
        (user) => user.account_number == props.selected_account_number
      );
    } else {
      user.value = {};
    }
  },
  { immediate: true }
);

async function search(account_number) {
  if (account_number) {
    try {
      const result = await userStore.searchUserUsingAccountNumber(account_number);
      searchedAccount.value = result;
    } catch (error) {
      console.error("Error searching for account:", error);
      searchedAccount.value = null;
    }
  } else {
    searchedAccount.value = null;
  }
}

onMounted(() => {});
</script>
