import { api } from "@/configs";
import { defineStore } from "pinia";
import { apiUrls } from "@/constants";

export const useDashboardStore = defineStore("dashboard", {
  state: () => ({
    transactionChart: [],
  }),
  actions: {
    async fetchAnalysis() {
      try {
        const response = await api.get(apiUrls.analysis);
        return response.data.data;
      } catch (error) {}
    },

    async fetchTransactionChart(yearMonth) {
      try {
        const response = await api.get(`${apiUrls.transactions}/${yearMonth}`);
        this.transactionChart = response.data.data;
      } catch (error) {
        console.error(error);
      }
    }
  },
});
