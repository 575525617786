import { usePlanStore } from "@/store/planStore";
import { plans } from "@/constants/enum";

/**
 * Fetches plans based on the provided plan type.
 *
 * @param {string} type - The type of plan (Gym, Massage, or Trainer).
 *
 * @returns {void}
 *
 * @throws Will throw an error if the provided type is not one of the expected values.
 */
export function fetchPlans(type){
    const planStore = usePlanStore();
    if(type === plans.Gym){
        planStore.fetchGymPlans({type: plans.Gym});
    } else if(type === plans.Massage){
        planStore.fetchMassagePlans({type: plans.Massage});
    } else if(type === plans.Trainer){
        planStore.fetchTrainerPlans({type: plans.Trainer});
    }
}

/**
 * Fetches lists based on the provided plan type.
 *
 * @param {string} type - The type of plan (Gym, Massage, or Trainer).
 *
 * @returns {void}
 *
 * @throws Will throw an error if the provided type is not one of the expected values.
 */
export function fetchLists(type){
    const planStore = usePlanStore();
    if(type === plans.Gym){
        planStore.fetchGymList({type: plans.Gym});
    } else if(type === plans.Massage){
        planStore.fetchMassageList({type: plans.Massage});
    } else if(type === plans.Trainer){
        planStore.fetchTrainerList({type: plans.Trainer});
    }
}

/**
 * Fetches periods based on the provided plan type.
 *
 * @param {string} type - The type of plan (Gym, Massage, or Trainer).
 *
 * @returns {void}
 *
 * @throws Will throw an error if the provided type is not one of the expected values.
 */
export function fetchPeriods(type){
    const planStore = usePlanStore();
    if(type === plans.Gym){
        planStore.fetchGymPeriods({type: plans.Gym});
    } else if(type === plans.Massage){
        planStore.fetchMassagePeriods({type: plans.Massage});
    } else if(type === plans.Trainer){
        planStore.fetchTrainerPeriods({type: plans.Trainer});
    }
}