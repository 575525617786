import { api } from "@/configs";
import { defineStore } from "pinia";
import { apiUrls } from "@/constants";
import { processUser, useUserStore } from "./userStore";

export const useSubscriptionStore = defineStore("subscription", {
  state: () => {
    return {
      subscriptions: [],
      unsyncedSubscriptions: [],
    };
  },
  getters: {
    // subscriptions() {
    //   useUserStore().users.filter(
    //     (user) => user.subscription.is_active == true
    //   );
    // },
  },
  actions: {
    async updateSubscription(subscription = { status, plan_id, user_id }) {
      try {
        const response = await api.patch(
          `${apiUrls.subscription}/${subscription.user_id}`,
          subscription
        );

        // update the use to skip fetching all user again
        const updatedUserIndex = useUserStore().users.findIndex(
          (user) => user.id == subscription.user_id
        );

        // update the user subscription details
        let user = useUserStore().users[updatedUserIndex];
        user.subscription = response.data.data;
        useUserStore().users[updatedUserIndex] = processUser(user);
        Swal.fire(`Subscription Successfully Updated`, "", "success");
        return true;
      } catch (error) {
        log(error);
        return false;
      }
    },
    async createSubscription(
      subscription = {
        user_id,
        plan_id,
        type,
        session_id,
        payment_type: "balance",
        end_date,
        start_date,
        old_member
      }
    ) {
      try {
        const type =
          subscription.type.charAt(0).toLowerCase() +
          subscription.type.slice(1);
        const requestBody = {
          user_id: subscription.user_id,
          plan_id: subscription.session_id,
          type: type,
          payment_type: "balance",
          end_date: subscription.end_date,
          start_date: subscription.start_date,
          old_member: subscription.old_member,
          already_paid: subscription.already_paid,
        };
        const status = await api.post(
          `${apiUrls.subscription}/payment/${requestBody.plan_id}`,
          requestBody
        );

        if(!status.data.status){
          return false;
        }
        // update the use to skip fetching all user again
        // const updatedUserIndex = useUserStore().users.findIndex(
        //   (user) => user.id == subscription.user_id
        // );

        // // update the user subscription details
        // let user = useUserStore().users[updatedUserIndex];
        // user.subscription = response.data.data;
        // useUserStore().users[updatedUserIndex] = processUser(user);
        Swal.fire(`Subscription Successfully Created`, "", "success");

        return true;
      } catch (error) {
        log(false);
        return false;
      }
    },
    async fetchSubscribers() {
      try {
        const response = await api.get(
          apiUrls.subscription + "/subscriptionList"
        );
        this.subscriptions = response.data.data;
      } catch (error) {}
    },

    async fetchUnSyncedSubscriptions() {
      try {
        const response = await api.get(
          `${apiUrls.subscription}/unsynced-subscriptionList`
        );
        this.unsyncedSubscriptions = response.data.data;
      } catch (error) {}
    },
  },
});
