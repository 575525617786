import { api } from "@/configs";
import { defineStore } from "pinia";
import { apiUrls } from "@/constants";
import { isAuth, setAccessToken } from "@/utils";
import Swal from "sweetalert2";
import { router } from "@/router";

/**
 * Main Store for Auth anbd Profile
 */
export const useMainStore = defineStore("main", {
  state: () => {
    return {
      profile: {},
      updates: [],
      isLoading: false,
      isAuth: isAuth(),
      showUpdates: false,
    };
  },
  actions: {
    async login({ email, password }) {
      try {
        const response = await api.post(apiUrls.login, { email, password });
        this.profile = response.data.data;
        setAccessToken(response.data.data.bearer_token);

        let next = router.currentRoute.value.query?.next;
        router.replace({ name: next ?? "dashboard" });
      } catch (error) {
        let message = "Please Check Your Network Connectivity";
        if (error?.response?.data?.message) {
          message = error.response.data.message;
        }
        Swal.fire(message, "", "error");
      }
    },
    /**
     * Fetch the Admin Profile
     */
    async fetchProfile() {
      try {
        const response = await api.get(apiUrls.profile);
        this.profile = response.data.data;
      } catch (error) {}
    },
    async fetchUpdates() {
      try {
        const response = await api.get(apiUrls.updates);
        this.updates = response.data.data;
      } catch (error) {}
    },
    async likeUpdate(updateId) {
      try {
        const response = await api.patch(`${apiUrls.updates}/${updateId}`);

        const index = this.updates.findIndex(
          (update) => update.id === updateId
        );
        this.updates[index].likes += 1;
        log(this.updates[index].likes)
      } catch (error) {}
    },
  },
});
