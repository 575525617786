import { api } from "@/configs";
import { defineStore } from "pinia";
import { apiUrls } from "@/constants";
import Swal from "sweetalert2";

// export function processUser(user) {
//   return {};
// }
export const useVotingStore = defineStore("voting", {
  state: () => {
    return {
      nominees: [],
      votingCategories: [],
    };
  },
  actions: {
    async fetchVotingCategories() {
      try {
        const response = await api.get(apiUrls.voting);
        this.votingCategories = response.data.data;
      } catch (error) {
        console.error(error);
      }
    },

    async fetchVotingNominees() {
      try {
        const response = await api.get(`${apiUrls.voting}/nominee`);
        this.nominees = response.data.data;
      } catch (error) {
        console.error(error);
      }
    },

    async addCategory(category) {
      try {
        // Convert `two_winners` from string to Boolean
        // category.two_winners = category.two_winners == "true";

        // Check `required_gender` and conditionally delete `two_winners`
        if (category.required_gender !== "Both") {
          delete category.two_winners;
        }

        const response = await api.post(
          `${apiUrls.voting}/category`,
          category,
          {
            headers: {
              "Content-Type": "multipart/form-data", // Ensure the correct content type
            },
          }
        );
        Swal.fire(`Category Added Successfully`, "", "success");
        this.fetchVotingCategories();
        return true;
        // this.fetchVotingCategories();
      } catch (error) {
        console.error(error);
        return false;
      }
    },

    async updateCategory(id, category) {
      try {
        // Remove 'two_winners' if the category is not 'Both'
        if (category.required_gender !== "Both") {
          delete category.two_winners;
        }

        // Ensure 'icon' is handled correctly
        if (!category.icon) {
          delete category.icon; // Remove 'icon' key if it's null or undefined
        } else if (!(category.icon instanceof File)) {
          delete category.icon; // Remove 'icon' if it's not a File object
        }

        // Prepare the request payload
        const formData = new FormData();
        formData.append("_method", "PATCH"); // Specify the HTTP method override
        Object.keys(category).forEach((key) => {
          if (
            category[key] instanceof File ||
            typeof category[key] !== "object"
          ) {
            // Handle file uploads or primitive values
            formData.append(key, category[key]);
          } else if (
            category[key] !== null &&
            typeof category[key] === "object"
          ) {
            // Stringify objects for the payload
            formData.append(key, JSON.stringify(category[key]));
          }
        });

        // Send the request
        const response = await api.post(
          `${apiUrls.voting}/category/${id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data", // Correct content type for file uploads
            },
          }
        );

        const updatedCategory = response.data.data;

        // Add the 'is_active' property locally (if needed)
        // updatedCategory["is_active"] = true;

        // Find and update the category in the local list
        // const index = this.votingCategories.findIndex((cat) => cat.id === id);
        // if (index !== -1) {
        //   this.votingCategories.splice(index, 1, updatedCategory);
        // }

        // Show success notification
        Swal.fire(`Category Updated Successfully`, "", "success");
        this.fetchVotingCategories();
        return true;
      } catch (error) {
        console.error("Error updating category:", error);
        Swal.fire(error, "Please try again.", "error");
        return false;
      }
    },
    async deleteVotingCategory(id) {
      try {
        await api.delete(`${apiUrls.voting}/category/${id}`);

        const deletedUserIndex = this.votingCategories.findIndex(
          (cat) => cat.id == id
        );
        Swal.fire(`Category Deleted Successfully`, "", "success");
        delete this.votingCategories[deletedUserIndex];
      } catch (error) {
        console.error(error);
      }
    },

    async addNominees(nominees) {
      try {
        const response = await api.post(
          `${apiUrls.voting}/nominee/${nominees.category_id}`,
          nominees
        );
        Swal.fire(`Nominee Added Successfully`, "", "success");
        this.fetchVotingNominees();
        return true;
      } catch (error) {
        console.error(error);
        return false;
      }
    },

    async deleteNominee(nomineeId) {
      try {
        await api.delete(`${apiUrls.voting}/nominee/${nomineeId}`);

        const deletedUserIndex = this.nominees.findIndex(
          (nominee) => nominee.id == nomineeId
        );
        Swal.fire(`Nominee Deleted Successfully`, "", "success");
        delete this.nominees[deletedUserIndex];
      } catch (error) {}
    },

    async updateCategoryStatus(id) {
      try {
        await api.patch(
          `${apiUrls.voting}/category/status/${id}`,
          // { _method: "PATCH", ...user },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        this.fetchVotingCategories();
        // update the use to skip fetching all user again
        // const updatedUserIndex = this.users.findIndex((user) => user.id == id);
        // this.users[updatedUserIndex] = processUser(response.data.data);
        // Swal.fire(`Admin Updated Successfully`, "", "success");
        return true;
      } catch (error) {
        log(error);
        return false;
      }
    },
  },
});
