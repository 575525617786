import { api } from "@/configs";
import { defineStore } from "pinia";
import { apiUrls } from "@/constants";

// export function processUser(user) {
//   return {};
// }
export const useAdminStore = defineStore("admin", {
  state: () => {
    return {
      admins: [],
      userTransactionHistory: [],
      splashscreen: [],
    };
  },
  actions: {
    async changePassword(new_password, current_password) {
      try {
        const password = { new_password, current_password };
        const response = await api.patch(
          `${apiUrls.admin}/changePassword`,
          password
        );
        if (response.data.status) return response.data.status;
        else return response.data.status;
      } catch (error) {
        console.log(error);
      }
    },

    async addAdmin(admin) {
      try {
        await api.post(apiUrls.admin, admin, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        await this.fetchAdmins();
        Swal.fire("User Added Successfully", "", "success");
        return true;
      } catch (error) {
        return false;
      }
    },

    async fetchAdmins() {
      try {
        const response = await api.get(apiUrls.admin);
        this.admins = response.data.data;
      } catch (error) {}
    },

    async updateAdminStatus(id, user) {
      try {
        const response = await api.patch(
          `${apiUrls.admin}/${id}`,
          // { _method: "PATCH", ...user },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        // update the use to skip fetching all user again
        // const updatedUserIndex = this.users.findIndex((user) => user.id == id);
        // this.users[updatedUserIndex] = processUser(response.data.data);
        // Swal.fire(`Admin Updated Successfully`, "", "success");
        return true;
      } catch (error) {
        log(error);
        return false;
      }
    },

    async updateAdminDetails(adminId, data) {
      try {
        await api.post(
          `${apiUrls.admin}/update/${adminId}`,
          { _method: "PATCH", ...data },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        Swal.fire(`Admin Updated Successfully`, "", "success");
        await this.fetchAdmins();
        return true;
      } catch (error) {
        log(error);
        return false;
      }
    },

    async deleteAdmin(adminId) {
      try {
        await api.delete(`${apiUrls.admin}/${adminId}`);

        const deletedAdminIndex = this.admins.findIndex(
          (admin) => admin.id == adminId
        );
        delete this.admins[deletedAdminIndex];
        Swal.fire(`Admin Deleted Successfully`, "", "success");
        return true;
      } catch (error) {
        return false;
      }
    },

    async fetchSplashScreen() {
      try {
        // Make the API request
        const response = await api.get(`${apiUrls.splashscreen}`);

        // Update the local splashscreen state
        this.splashscreen = response.data?.data || null;
      } catch (error) {
        // Handle and log the error
        console.error("Error fetching splash screen:", error);

        // Optionally, you can set a fallback state or show a user-friendly message
        this.splashscreen = [];
      }
    },

    async uploadSplashScreen(splashscreen) {
      try {
        await api.post(apiUrls.splashscreen, splashscreen, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        await this.fetchSplashScreen();
        Swal.fire("Splash Screen Added Successfully", "", "success");
        return true;
      } catch (error) {
        return false;
      }
    },

    async updateSplashScreen(splashscreenId, splashscreen) {
      try {
        // Prepare the form data
        const formData = new FormData();
        formData.append("_method", "PATCH"); // Laravel's way to handle PATCH requests
        for (const key in splashscreen) {
          if (splashscreen[key]) {
            formData.append(key, splashscreen[key]);
          }
        }

        // Send the request
        await api.post(`${apiUrls.splashscreen}/${splashscreenId}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        // Refresh the splash screen data
        await this.fetchSplashScreen();

        // Show success alert
        Swal.fire("Splash Screen Updated Successfully", "", "success");
        return true;
      } catch (error) {
        console.error("Error updating splash screen:", error);
        Swal.fire("Failed to update Splash Screen", "", "error");
        return false;
      }
    },

    async deleteSplashScreen(splashscreenId) {
      try {
        await api.delete(`${apiUrls.splashscreen}/${splashscreenId}`);

        const deletedSplashIndex = this.splashscreen.findIndex(
          (splash) => splash.id == splashscreenId
        );
        delete this.splashscreen[deletedSplashIndex];
        Swal.fire(`Splash Screen Deleted Successfully`, "", "success");
        return true;
      } catch (error) {
        return false;
      }
    },

    async updateSplashScreenStatus(id) {
      try {
        await api.patch(
          `${apiUrls.splashscreen}/status/${id}`,
          // { _method: "PATCH", ...user },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        // update the use to skip fetching all user again
        // const updatedUserIndex = this.users.findIndex((user) => user.id == id);
        // this.users[updatedUserIndex] = processUser(response.data.data);
        // Swal.fire(`Admin Updated Successfully`, "", "success");
        return true;
      } catch (error) {
        log(error);
        return false;
      }
    },
  },
});
