<template>
    <div class="update-card col-5">
        <h2 class="update-title">{{ update.title }}</h2>
        <p class="update-date">{{ update.date }}</p>
        <p class="update-description" v-html="update.message"></p>
        <div>
            <div @click="likeUpdate()" class="like liked" style="">
                <i class="icon-like"></i>
                {{ update.likes }}
            </div>
        </div>
    </div>
</template>
<script setup>
import { useMainStore } from "@/store/mainStore";

const mainStore = useMainStore();
const props = defineProps({
    update: Object
});

function likeUpdate() {
    mainStore.likeUpdate(props.update.id);
}
</script>

<style scoped>
.update-card {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
}

.update-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.update-title {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: var(--PRIMARY-COLOR);
}

.update-date {
    font-size: 0.9rem;
    color: #95a5a6;
    margin-bottom: 15px;
}

.update-description {
    font-size: 1rem;
    color: #2c3e50;
}

.like {
    color: var(--PRIMARY-COLOR);
    cursor: pointer;
    margin-top: 6px;
}

.liked {
    background: var(--PRIMARY-COLOR);
    color: #fff !important;
    border-radius: 10px;
    width: fit-content;
    padding: 3px 6px;
}

@media (max-width: 768px) {
    .updates-container {
        flex-direction: column;
        align-items: center;
    }
}
</style>