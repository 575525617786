<template>
  <!-- Add Plan -->
  <div class="modal fade" id="splash-modal">
    <div class="modal-dialog modal-dialog-centered custom-modal-two">
      <div class="modal-content">
        <div class="page-wrapper-new p-0">
          <div class="content">
            <div class="modal-header border-0 custom-modal-header">
              <div class="page-title">
                <h4>Splash Screen</h4>
              </div>
              <button
                type="button"
                class="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body custom-modal-body">
              <form @submit.prevent="submitForm">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="input-blocks">
                      <label>Start Date</label>
                      <input
                        type="date"
                        name="start_date"
                        required
                        v-model="splashscreen.start_date"
                        :class="{ 'form-control': true }"
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="input-blocks">
                      <label>End Date</label>
                      <input
                        type="date"
                        name="end_date"
                        required
                        v-model="splashscreen.end_date"
                        :class="{ 'form-control': true }"
                      />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-6">
                    <div class="input-blocks">
                      <label>Title</label>
                      <input
                        type="text"
                        name="title"
                        required
                        v-model="splashscreen.title"
                        :class="{ 'form-control': true }"
                      />
                    </div>
                  </div>
                </div>

                <!-- Image Upload Section -->
                <div class="row">
                  <div class="col-lg-12">
                    <div class="input-blocks">
                      <label>Banner</label>
                      <div class="custom-file">
                        <input
                          type="file"
                          id="file-input"
                          @change="previewImage"
                          accept="image/*"
                          class="custom-file-input"
                        />
                        <label for="file-input" class="custom-file-label"
                          >Choose Banner</label
                        >
                      </div>
                    </div>
                  </div>

                 
                  <div class="col-lg-12" v-if="imageUrl">
                    <div class="image-preview">
                      <img :src="getImageUrl(imageUrl)" alt="Image Preview" class="img-thumbnail" />
                      <button type="button" @click="removeImage" class="btn remove-btn">
                        ✖
                      </button>
                    </div>
                  </div>
                </div>

                <div class="modal-footer-btn">
                  <button
                    type="button"
                    class="btn btn-cancel me-2"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button type="submit" class="btn btn-submit" id="submit">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Plan -->
</template>
<script setup>
import { checkInputValidity } from "@/utils/validator";
import { computed, onMounted, reactive, ref, watch } from "vue";
import { useAdminStore } from "@/store/adminStore";
// stores
const adminStore = useAdminStore();

const props = defineProps({
  splashScreenId: null,
});
// data
const splashscreen = reactive({
  title: "",
  start_date: "",
  end_date: "",
  image: null, // Single file object
});
const imageUrl = ref(null); // Single preview URL

const previewImage = (event) => {
  const file = event.target.files[0]; // Get the first file
  if (file) {
    imageUrl.value = URL.createObjectURL(file); // Generate a preview URL
    splashscreen.image = file; // Assign the file to the splashscreen object
  }
};

const removeImage = () => {
  imageUrl.value = null; // Clear the preview URL
  splashscreen.image = null; // Clear the file from the splashscreen object
};

const getImageUrl = (image) => {
  // Check if image is from the server or locally added
  return typeof image === "object" && image?.url ? image.url : imageUrl.value;
};
async function submitForm() {
  if (props.splashScreenId) {
    await adminStore.updateSplashScreen(props.splashScreenId, splashscreen);
  } else {
    // add
    await adminStore.uploadSplashScreen(splashscreen);
    // await planStore.fetchGymList({type:plans.Gym})
  }
  $("#splash-modal").modal("hide");
}

watch(
  () => splashscreen,
  () => {
    // setTimeout is used here so the service will be passed to the input b4 this runs
    setTimeout(() => checkInputValidity("submit"), 5);
  },
  { deep: true }
);

watch(
  () => props.splashScreenId,
  (newSplashscreenId) => {
    imageUrl.value = null; // Set to null instead of an array
    if (newSplashscreenId) {
      const existingSplashScreen = adminStore.splashscreen.find(
        (splashscreen) => splashscreen.id == newSplashscreenId
      );
      if (existingSplashScreen) {
        Object.assign(splashscreen, existingSplashScreen);
        splashscreen.image = null; // Reset the image field
        imageUrl.value = existingSplashScreen.image; // Assign the existing image URL
      }
    } else {
      Object.assign(splashscreen, {
        title: "",
        start_date: "",
        end_date: "",
        image: null, // Reset to null
      });
    }
  },
  { immediate: true }
);

onMounted(() => {});
</script>

<style scoped>
/* Styling for image previews */
.image-preview {
  position: relative;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
}

.image-preview img {
  max-width: 150px;
  max-height: 150px;
  border-radius: 8px;
}

.remove-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  color: rgb(247, 7, 7);
  border: none;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  cursor: pointer;
  font-size: 14px;
}

/* Custom file input styling */
.custom-file {
  position: relative;
  display: inline-block;
}

.custom-file-input {
  display: none; /* Hide the default file input */
}

.custom-file-label {
  display: inline-block;
  padding: 10px 20px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  background-color: #ffffff;
  color: #495057;
  cursor: pointer; /* Change cursor to pointer on hover */
  transition: background-color 0.3s, border-color 0.3s;
}

/* Hover and focus effects */
.custom-file-label:hover {
  background-color: #f8f9fa; /* Light gray on hover */
  border-color: #80bdff; /* Blue border on hover */
}

.custom-file-label:focus {
  outline: none; /* Remove outline on focus */
  border-color: #80bdff; /* Blue border on focus */
}
</style>
