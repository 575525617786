<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>
  <div class="page-wrapper">
    <div class="content">
      <!-- <GymNavigationButtons /> -->
      <div class="page-header">
        <div class="add-item d-flex">
          <div class="page-title">
            <h4>Voting Nominee List</h4>
            <!-- <h6>Manage Your Products</h6> -->
          </div>
        </div>
        <ul class="table-top-head">
          <!-- <li>
            <a data-bs-toggle="tooltip" data-bs-placement="top" title="Refresh"
              ><i data-feather="rotate-ccw" class="feather-rotate-ccw"></i
            ></a>
          </li> -->
          <li>
            <a
              ref="collapseHeader"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Collapse"
              @click="toggleCollapse(collapseHeader)"
            >
              <i data-feather="chevron-up" class="feather-chevron-up"></i>
            </a>
          </li>
        </ul>
        <div class="page-btn">
          <a href="javascript:void(0);" class="btn btn-added" @click="openModal('new')"
            ><vue-feather type="plus-circle" class="me-2"></vue-feather>Add To List
          </a>
        </div>
      </div>
      <!-- USER TABLE -->
      <!-- {{ ProductList.data }} + -->
      <filter-table
        :extra-class="['table', 'datanew']"
        :columns="Nominees.columns"
        :data="Nominees.data"
        :filters="Nominees.filters"
      >
        <template #header>
          <!-- <h4>Available Products</h4> -->
        </template>
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'category'">{{ record.category.name }}</template>
          <template v-if="column.key === 'available_quantity'">
            <a href="#" @click="openRestockHistory(record.id)">{{
              record.available_quantity
            }}</a>
          </template>
          <!-- <template v-else-if="column.key === 'account'">
            {{ record.account_details?.account_number }}
          </template> -->
          <template v-else-if="column.key === 'status'">
            <a-switch
              :checked="record.is_active"
              @change="updateSplashScreenStatus(record.id)"
            />
          </template>
          <template v-if="column.key === 'action'">
            <td class="action-table-data">
              <div class="edit-delete-action">
                <!-- <a - -->
                <a
                  @click="deleteNominee(record.id)"
                  class="me-2 confirm-text p-2 mb-0"
                  href="javascript:void(0);"
                >
                  <i data-feather="trash-2" class="feather-trash-2"></i>
                </a>
              </div>
            </td>
          </template>
        </template>
      </filter-table>
      <!-- USER TABLE -->
    </div>
  </div>
  <votingNomineeListModal :nominee-id="selectedNomineeId" ref="nomineeModal" />
</template>

<script setup>
import votingNomineeListModal from "./modal/voting-nominee-list-modal1.vue";
import { computed, onMounted, ref } from "vue";
import { useAdminStore } from "@/store/adminStore";
import { confirm } from "@/utils/swal";
import { toggleCollapse } from "@/utils/components";
import { useVotingStore } from "@/store/VotingStore";
import { useUserStore } from "@/store/userStore";
// store
const nomineeStore = useVotingStore();
const userStore = useUserStore();
// data
/**
 * Id of Plan that is being edited
 */
const selectedNomineeId = ref(null);

const nomineeModal = ref(null);
const Nominees = ref({
  filters: ["title"],
  columns: [
    {
      title: "Name",
      dataIndex: "username",
      sorter: {
        compare: (a, b) => {
          a = a.username.toLowerCase();
          b = b.username.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Gender",
      dataIndex: "gender",
      sorter: {
        compare: (a, b) => {
          a = a.gender.toLowerCase();
          b = b.gender.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Category",
      dataIndex: "voting_category",
      sorter: {
        compare: (a, b) => {
          a = a.voting_category.toLowerCase();
          b = b.voting_category.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Vote Count",
      dataIndex: "vote_count",
      sorter: {
        compare: (a, b) => a.vote_count - b.vote_count,
      },
    },
    // {
    //   title: "Status",
    //   key: "status",
    //   sorter: true,
    // },
    {
      title: "Action",
      key: "action",
      sorter: true,
    },
  ],
  data: computed(() => nomineeStore.nominees),
});

const canAddNewPlan = computed(() => adminStore.products.length < 3);
// ref elements
const collapseHeader = ref(null);
// Methods
async function deleteNominee(id) {
  confirm({
    title: "Are you sure about this?",
    callback: async (continueDelete) => {
      if (continueDelete) {
        await nomineeStore.deleteNominee(id);
      }
    },
  });
}

async function openModal(action, id = null) {
  if (action == "update") {
    selectedNomineeId.value = id;
  } else {
    selectedNomineeId.value = null;
  }

  await userStore.fetchUsers();
  if (nomineeStore.votingCategories.length < 1) {
    await nomineeStore.fetchVotingCategories();
  }

  // $("#voting-nominee-modal").modal("show");
  setTimeout(() => {
    log(nomineeModal.value.openModal());
  }, 5);
}

// async function updateSplashScreenStatus(userId) {
//   await adminStore.updateSplashScreenStatus(userId);
//   await adminStore.fetchSplashScreen();
// }

onMounted(async () => {
  // if (Nominees.value.data.length == 0) {
  await nomineeStore.fetchVotingNominees();
  // }
});
</script>
