<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>
  <div class="page-wrapper">
    <div class="content">
      <!-- <GymNavigationButtons /> -->
      <div class="page-header">
        <div class="add-item d-flex">
          <div class="page-title">
            <h4>Vendor List</h4>
            <h6>Manage Your Vendors</h6>
          </div>
        </div>
        <ul class="table-top-head">
          <!-- <li>
            <a data-bs-toggle="tooltip" data-bs-placement="top" title="Refresh"
              ><i data-feather="rotate-ccw" class="feather-rotate-ccw"></i
            ></a>
          </li> -->
          <li>
            <a
              ref="collapseHeader"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Collapse"
              @click="toggleCollapse(collapseHeader)"
            >
              <i data-feather="chevron-up" class="feather-chevron-up"></i>
            </a>
          </li>
        </ul>
        <div class="page-btn">
          <a
            href="javascript:void(0);"
            class="btn btn-added"
            @click="openModal('new')"
            ><vue-feather type="plus-circle" class="me-2"></vue-feather>Add To List
          </a>
        </div>
      </div>
      <!-- USER TABLE -->
       <!-- {{ ProductList.data }} + -->
      <filter-table
        :extra-class="['table', 'datanew']"
        :columns="VendorList.columns"
        :data="VendorList.data"
        :filters="VendorList.filters"
      >
        <template #header>
          <h4>Available Vendor</h4>
        </template>
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'name'">
            <div class="userimgname gap-2 align-items-center">
              <div>
                <a href="javascript:void(0);">{{ record.name }}</a>
              </div>
            </div>
          </template>
          <template v-if="column.key === 'action'">
            <td class="action-table-data">
              <div class="edit-delete-action">
                <!-- <a @click="openModal('update', record.id)" class="me-2 p-2 mb-0">
                  <i data-feather="edit" class="feather-edit"></i>
                </a> -->
                <a
                  @click="deleteVendor(record.id)"
                  class="me-2 confirm-text p-2 mb-0"
                  href="javascript:void(0);"
                >
                  <i data-feather="trash-2" class="feather-trash-2"></i>
                </a>
                <a
                  @click="openVendorOrderedProductModal(record.id)"
                  class="me-2 confirm-text p-2 mb-0"
                  href="javascript:void(0);"
                >
                  <i data-feather="dollar-sign" class="feather-dollar-sign"></i>
                </a>
                <a
                  @click="openVendorProductModal(record.id)"
                  class="me-2 confirm-text p-2 mb-0"
                  href="javascript:void(0);"
                >
                  <i data-feather="box" class="feather-box"></i>
                </a>
              </div>
            </td>
          </template>
        </template>
      </filter-table>
      <!-- USER TABLE -->
    </div>
  </div>
  <VendorModal :vendor-id="selectedVendorId"/>
  <VendorProductModal :vendor-id="selectedVendorId"/>
  <VendorOrderedProductModal :vendor-id="selectedVendorId"/>
</template>

<script setup>
import GymNavigationButtons from '@/views/layouts/gym-setting.vue';
import VendorModal from "./modal/vendor-list-modal.vue";
import { computed, onMounted, ref } from "vue";
import Swal from "sweetalert2";
import { useProductStore } from '@/store/productStore';
import { formatAmount } from "@/utils";
import { confirm } from "@/utils/swal";
import { toggleCollapse } from "@/utils/components";
import { plans } from "@/constants/enum";
import { useRouter } from "vue-router";
import VendorProductModal from '@/views/pages/vendor/modal/VendorProductModal.vue'
import VendorOrderedProductModal from '@/views/pages/vendor/modal/VendorOrderedProductModal.vue'
// store
const vendorStore = useProductStore();
const router = useRouter();
// data
/**
 * Id of Plan that is being edited
 */
const selectedVendorId = ref(null);
const VendorList = ref({
  filters: ["name", "email"],
  columns: ref([
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: {
        compare: (a, b) => {
          a = a.name.toLowerCase();
          b = b.name.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: {
        compare: (a, b) => {
          a = a.email.toLowerCase();
          b = b.email.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Mobile Number",
      dataIndex: "mobile_number",
      sorter: {
        compare: (a, b) => {
          a = a.mobile_number.toLowerCase();
          b = b.mobile_number.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      sorter: true,
    },
    {
      title: "Action",
      key: "action",
      sorter: true,
    },
  ]),
  data: computed(() => vendorStore.vendors),
});

const canAddNewPlan = computed(() => vendorStore.vendors.length < 3);
// ref elements
const collapseHeader = ref(null);
// Methods
async function deleteVendor(id) {
  confirm({
    title: "Are you sure about this?",
    message: "All the products under this Vendor will be deleted.",
    callback: async (continueDelete) => {
      if (continueDelete) {
        await vendorStore.deleteVendor(id);
      }
    },
  });
}

function openModal(action, id = null) {
  if (action == "update") {
    selectedVendorId.value = id;
  } else {
    selectedVendorId.value = null;
  }
  $("#vendor-modal").modal("show");
}



function openVendorProductModal(vendorId)
{
  selectedVendorId.value = vendorId;
  $("#vendor-product-modal").modal("show");
}

async function openVendorOrderedProductModal(vendorId)
{
  selectedVendorId.value = vendorId;
  await vendorStore.fetchVendorOrderedProducts(vendorId)
  $("#vendor-ordered-product-modal").modal("show");
}


async function updateProductStatus(isActive, userId) {
  await vendorStore.updateProductStatus(userId, { status: isActive ? "active" : "inactive" });
  await vendorStore.fetchProducts();
}

onMounted(async () => {
  if (VendorList.value.data.length == 0) {
    await vendorStore.fetchVendors();
  }
  await vendorStore.fetchProducts();
});
</script>


