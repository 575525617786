<template>
  <div class="button-wrapper d-flex justify-content-start mt-3">
    <button
      class="btn mx-2"
      :class="{'btn-primary': currentRoute.name === 'gym-list', 'btn-outline-primary': currentRoute.name !== 'gym-list'}"
      @click="navigateTo('gym-list')"
    >
      Gym List
    </button>
    <button
      class="btn mx-2"
      :class="{'btn-secondary': currentRoute.name === 'gym-period', 'btn-outline-secondary': currentRoute.name !== 'gym-period'}"
      @click="navigateTo('gym-period')"
    >
      Gym Periods
    </button>
  </div>
</template>

<script setup>
import { useRouter, useRoute } from 'vue-router';
import { computed } from 'vue';

const router = useRouter();
const route = useRoute();

const currentRoute = computed(() => route);

function navigateTo(page) {
  router.push({ name: page });
}
</script>

<style scoped>
.button-wrapper {
  margin-bottom: 20px;
}

.btn-primary, .btn-secondary {
  color: #fff;
}

.btn-outline-primary, .btn-outline-secondary {
  color: #000;
  border: 1px solid;
}

.btn-outline-primary {
  border-color: #007bff;
}

.btn-outline-secondary {
  border-color: #6c757d;
}
</style>
