<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>
  <div class="page-wrapper">
    <div class="content">
      <div class="page-header">
        <div class="add-item d-flex">
          <div class="page-title">
            <h4>Massage List</h4>
            <h6>Manage Your Massage List</h6>
          </div>
        </div>
        <ul class="table-top-head">
          <!-- <li>
            <a data-bs-toggle="tooltip" data-bs-placement="top" title="Refresh"
              ><i data-feather="rotate-ccw" class="feather-rotate-ccw"></i
            ></a>
          </li> -->
          <li>
            <a
              ref="collapseHeader"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Collapse"
              @click="toggleCollapse(collapseHeader)"
            >
              <i data-feather="chevron-up" class="feather-chevron-up"></i>
            </a>
          </li>
        </ul>
        <div class="page-btn">
          <a
            v-if="canAddNewPlan"
            href="javascript:void(0);"
            class="btn btn-added"
            @click="openModal('new')"
            ><vue-feather type="plus-circle" class="me-2"></vue-feather>Add New Plan
          </a>
        </div>
      </div>
      <!-- USER TABLE -->
      <filter-table
        :extra-class="['table', 'datanew']"
        :columns="MassageList.columns"
        :data="MassageList.data"
        :filters="MassageList.filters"
      >
        <template #header>
          <h4>Available Plans</h4>
        </template>
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'action'">
            <td class="action-table-data">
              <div class="edit-delete-action">
                <a @click="openModal('update', record.id)" class="me-2 p-2 mb-0">
                  <i data-feather="edit" class="feather-edit"></i>
                </a>
                <a
                  @click="deletePlan(record.id)"
                  class="me-2 confirm-text p-2 mb-0"
                  href="javascript:void(0);"
                >
                  <i data-feather="trash-2" class="feather-trash-2"></i>
                </a>
              </div>
            </td>
          </template>
        </template>
      </filter-table>
      <!-- USER TABLE -->
    </div>
  </div>
  <PlanModal :plan-id="selectedPlanId" />
</template>

<script setup>
import PlanModal from "./modal/massage-list-modal.vue";
import { computed, onMounted, ref } from "vue";
import Swal from "sweetalert2";
import { usePlanStore } from "@/store/planStore";
import { formatAmount } from "@/utils";
import { confirm } from "@/utils/swal";
import { toggleCollapse } from "@/utils/components";
import {plans} from "@/constants/enum"
// store
const planStore = usePlanStore();

// data
/**
 * Id of Plan that is being edited
 */
const selectedPlanId = ref(null);
const MassageList = {
  filters: ["name"],
  columns: [
    {
      title: "Name",
      dataIndex: "type",
      key: "type",
      sorter: {
        compare: (a, b) => {
          a = a.type.toLowerCase();
          b = b.type.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    // {
    //   title: "Duration",
    //   key: "months",
    //   sorter: {
    //     compare: (a, b) => {
    //       a = a.months.toLowerCase();
    //       b = b.months.toLowerCase();
    //       return a > b ? -1 : b > a ? 1 : 0;
    //     },
    //   },
    // },
    {
      title: "Action",
      key: "action",
      sorter: true,
    },
  ],
  data: computed(() => planStore.MassageList),
};

const canAddNewPlan = computed(() => planStore.subscriptionPlans.length < 3);
// ref elements
const collapseHeader = ref(null);
// Methods
async function deletePlan(id) {
  confirm({
    title: "Are you sure about this?",
    message: "This plan and all Subscriptions attached to it will be deleted",
    callback: async (continueDelete) => {
      if (continueDelete) {
        await planStore.deleteServiceType(id, plans.Massage);
      }
    },
  });
}

function openModal(action, id = null) {
  if (action == "update") {
    selectedPlanId.value = id;
  } else {
    selectedPlanId.value = null;
  }
  $("#plan-modal").modal("show");
}

onMounted(async () => {
  if (MassageList.data.value.length == 0) {
    await planStore.fetchMassageList({type:plans.Massage});
  }
});
</script>
