export const devotionTypes = {
  dailyDevotion: "Daily Devotion",
  word: "Word Of The Day",
};

export const subscriptionStatus = {
  active: "Active",
  inactive: "Inactive",
  expired: "Expired",
};

export const Envs = {
  local: "local",
  demo: "demo",
  production: "production",
};

export const plans = {
  Gym: "gym",
  Massage: "massage",
  Trainer: "trainer",
};
