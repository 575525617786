<template>
    <div>
        <layout-header></layout-header>
        <layout-sidebar></layout-sidebar>
        <div class="page-wrapper">
            <div class="content">
                <div class="row" v-if="devotion">
                    <div class="col-lg-8 col-md-8 col-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="row row-cols-lg-2 row-cols-md-2 " v-if="comments?.length > 0">
                                    <div class="col-lg-6 col-md-6">
                                        <!-- <CommentCard :devotionId="devotion.id"
                                            v-for="comment in comments.slice(0, commentHalfLength)"
                                            :comment="comment" />
                                    </div>

                                    <div class="col-lg-6 col-md-6">
                                        <CommentCard :devotionId="devotion.id"
                                            v-for="comment in comments.slice(commentHalfLength, comments.length)"
                                            :comment="comment" /> -->
                                    </div>
                                </div>
                                <div v-else>
                                    <h4>No Comment Yet</h4>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                        <div class="card">
                            <div class="card-body">

                                <img height="150" class="rounded-2" :src="devotion.thumbnail" />
                                <h5 class="text-center mb-3">{{ devotion.title }}</h5>
                                <div style="overflow: hidden; word-break: break-all; " v-html="devotion.content">
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import { useDevotionStore } from "@/store/devotionStore";
import CommentCard from "./components/comment-card.vue";
import { useRoute } from "vue-router";
import { useCommentStore } from "@/store/commentStore";
import { decodeUrlData } from "./utils/data";

// hooks
const route = useRoute();
// store
const devotionStore = useDevotionStore();
const commentStore = useCommentStore();

// data
const urlData = computed(() => decodeUrlData(route.params['encodedData']));

const devotion = ref({});
// const comments = computed(() => devotion.value ? devotion.value?.analysis?.comments : []);
const comments = ref([]);
const commentHalfLength = computed(() => {
    if (!comments.value) {
        return 0
    }
    else {
        return comments.value.length > 1 ? Math.round(comments.value.length / 2) : 1;
    }
});

async function loadDevotionComments() {
    comments.value = await commentStore.fetchComments(devotion.value.id);
}

async function loadData() {
    devotion.value = devotionStore.findById(urlData.value.devotionId)?.devotion;
    if (!devotion.value) {
        const data = await devotionStore.fetchDevotionByDate(urlData.value.date, urlData.value.type);
        devotion.value = { ...data };
    }
}
onMounted(async () => {
    await loadData();
    await loadDevotionComments();
});
</script>
<style>
.round-avatar {
    width: 50px;
    height: 50px;
    max-width: 100%;
    margin-right: 0;
    border-radius: 20%;
}
</style>
