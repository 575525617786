<template>
  <!-- Header -->
  <div class="header">
    <!-- Logo -->
    <div class="header-left active">
      <router-link to="/dashboard" class="logo logo-normal">
        <img src="@/assets/img/prolife_logo.png" alt="" />
      </router-link>
      <router-link to="/dashboard" class="logo logo-white">
        <img src="@/assets/img/logo-white.png" alt="" />
      </router-link>
      <router-link to="/dashboard" class="logo-small">
        <img src="@/assets/img/prolife_logo.png" alt="" />
      </router-link>
      <a id="toggle_btn" href="javascript:void(0);" @click="toggleSidebar">
        <vue-feather type="chevrons-left"></vue-feather>
      </a>
    </div>
    <!-- /Logo -->

    <a id="mobile_btn" class="mobile_btn" href="javascript:void(0);" @click="toggleSidebar1">
      <span class="bar-icon">
        <span></span>
        <span></span>
        <span></span>
      </span>
    </a>

    <!-- Header Menu -->
    <ul class="nav user-menu">
      <!-- Search -->
      <li class="nav-item nav-searchinputs">
        <div class="top-nav-search">
          <a href="javascript:void(0);" class="responsive-search">
            <i class="fa fa-search"></i>
          </a>
          
        </div>
      </li>
      <!-- /Search -->

      <li class="scroll-text-container">
        <div class="scroll-text">
          <h2>Word Of The Day</h2>
        </div>
      </li>
      <li class="nav-item nav-item-box">
        <a href="javascript:void(0);" id="btnFullscreen" @click="initFullScreen">
          <vue-feather type="maximize"></vue-feather>
        </a>
      </li>
      <!-- <li class="nav-item nav-item-box">
        <router-link to="/application/email">
          <vue-feather type="mail"></vue-feather>
          <span class="badge rounded-pill">1</span>
        </router-link>
      </li> -->
      <!-- Notifications -->
      <!-- <li class="nav-item dropdown nav-item-box">
        <a
          href="javascript:void(0);"
          class="dropdown-toggle nav-link"
          data-bs-toggle="dropdown"
        >
          <vue-feather type="bell"></vue-feather><span class="badge rounded-pill">2</span>
        </a>
        <div class="dropdown-menu notifications">
          <div class="topnav-dropdown-header">
            <span class="notification-title">Notifications</span>
            <a href="javascript:void(0)" class="clear-noti"> Clear All </a>
          </div>
          <div class="noti-content">
            <ul class="notification-list">
              <li class="notification-message">
                <router-link to="/activities">
                  <div class="media d-flex">
                    <span class="avatar flex-shrink-0">
                      <img alt="" src="@/assets/img/profiles/avatar-02.jpg" />
                    </span>
                    <div class="media-body flex-grow-1">
                      <p class="noti-details">
                        <span class="noti-title">John Doe</span> added new task
                        <span class="noti-title">Patient appointment booking</span>
                      </p>
                      <p class="noti-time">
                        <span class="notification-time">4 mins ago</span>
                      </p>
                    </div>
                  </div>
                </router-link>
              </li>
              <li class="notification-message">
                <router-link to="/activities">
                  <div class="media d-flex">
                    <span class="avatar flex-shrink-0">
                      <img alt="" src="@/assets/img/profiles/avatar-03.jpg" />
                    </span>
                    <div class="media-body flex-grow-1">
                      <p class="noti-details">
                        <span class="noti-title">Tarah Shropshire</span> changed the task
                        name
                        <span class="noti-title"
                          >Appointment booking with payment gateway</span
                        >
                      </p>
                      <p class="noti-time">
                        <span class="notification-time">6 mins ago</span>
                      </p>
                    </div>
                  </div>
                </router-link>
              </li>
              <li class="notification-message">
                <router-link to="/activities">
                  <div class="media d-flex">
                    <span class="avatar flex-shrink-0">
                      <img alt="" src="@/assets/img/profiles/avatar-06.jpg" />
                    </span>
                    <div class="media-body flex-grow-1">
                      <p class="noti-details">
                        <span class="noti-title">Misty Tison</span> added
                        <span class="noti-title">Domenic Houston</span> and
                        <span class="noti-title">Claire Mapes</span> to project
                        <span class="noti-title">Doctor available module</span>
                      </p>
                      <p class="noti-time">
                        <span class="notification-time">8 mins ago</span>
                      </p>
                    </div>
                  </div>
                </router-link>
              </li>
              <li class="notification-message">
                <router-link to="/activities">
                  <div class="media d-flex">
                    <span class="avatar flex-shrink-0">
                      <img alt="" src="@/assets/img/profiles/avatar-17.jpg" />
                    </span>
                    <div class="media-body flex-grow-1">
                      <p class="noti-details">
                        <span class="noti-title">Rolland Webber</span> completed task
                        <span class="noti-title"
                          >Patient and Doctor video conferencing</span
                        >
                      </p>
                      <p class="noti-time">
                        <span class="notification-time">12 mins ago</span>
                      </p>
                    </div>
                  </div>
                </router-link>
              </li>
              <li class="notification-message">
                <router-link to="/activities">
                  <div class="media d-flex">
                    <span class="avatar flex-shrink-0">
                      <img alt="" src="@/assets/img/profiles/avatar-13.jpg" />
                    </span>
                    <div class="media-body flex-grow-1">
                      <p class="noti-details">
                        <span class="noti-title">Bernardo Galaviz</span> added new task
                        <span class="noti-title">Private chat module</span>
                      </p>
                      <p class="noti-time">
                        <span class="notification-time">2 days ago</span>
                      </p>
                    </div>
                  </div>
                </router-link>
              </li>
            </ul>
          </div>
          <div class="topnav-dropdown-footer">
            <router-link to="/activities">View all Notifications</router-link>
          </div>
        </div>
      </li> -->
      <!-- /Notifications -->

      <li class="nav-item dropdown has-arrow main-drop">
        <a href="javascript:void(0);" class="dropdown-toggle nav-link userset" data-bs-toggle="dropdown">
          <span class="user-info">
            <span class="user-letter">
              {{ profile }}
              <a href="javascript:void(0);" class="userslist-img bg-img gap-2">
                <avatar :src="profile.profile_picture" :alt="profile.first_name" img-class="user-image" />
              </a>
            </span>
            <span class="user-detail">
              <span class="user-name">{{ profile.first_name }} {{profile.last_name}}</span>
              <span class="user-role">Super Admin</span>
            </span>
          </span>
        </a>
        <div class="dropdown-menu menu-drop-user">
          <div class="profilename">
            <div class="profileset">
              <span class="user-img">
                <a href="javascript:void(0);" class="userslist-img bg-img gap-2">
                  <avatar :src="profile.profile_picture" :alt="profile.first_name" img-class="user-image" />
                </a>
                <span class="status online"></span></span>
              <div class="profilesets">
                <h6>{{ profile.first_name }} {{profile.last_name}}</h6>
                <h5>Super Admin</h5>
              </div>
            </div>
            <hr class="m-0" />
            <a class="dropdown-item" to="/profile" href="javascript:void(0);" @click="passwordModal()">
              <vue-feather class="me-2" type="user"></vue-feather>Change Password</a
            >
            <hr class="m-0" />
            <router-link class="dropdown-item logout pb-0" to="/"><img src="@/assets/img/icons/log-out.svg" class="me-2"
                alt="img" />Logout</router-link>
          </div>
        </div>
      </li>
    </ul>
    <!-- /Header Menu -->

    <!-- Mobile Menu -->
    <div class="dropdown mobile-user-menu">
      <a href="javascript:void(0);" class="nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i
          class="fa fa-ellipsis-v"></i></a>
      <div class="dropdown-menu dropdown-menu-right">
        <router-link class="dropdown-item" to="/pages/profile">My Profile</router-link>
        <router-link class="dropdown-item" to="/settings/general-settings">Settings</router-link>
        <router-link class="dropdown-item" to="/">Logout</router-link>
      </div>
    </div>
    <!-- /Mobile Menu -->
  </div>
  <!-- /Header -->
  <PasswordModal
  ref="userModal"
/>
  <side-settings></side-settings>
</template>

<script setup>
import { mapStores, storeToRefs } from "pinia";
import { useMainStore } from "@/store/mainStore";
import { onMounted, onBeforeUnmount, computed, ref } from "vue";
import PasswordModal from "./modal/password-modal.vue";



function passwordModal() {
  $("#password-modal").modal("show");
}

const mainStore = useMainStore();

const profile = computed(() => mainStore.profile);

const toggleSidebar1 = () => {
  const body = document.body;
  body.classList.toggle("slide-nav");
};

const toggleSidebar = () => {
  const body = document.body;
  body.classList.toggle("mini-sidebar");
};

const initFullScreen = () => {
  document.body.classList.toggle("fullscreen-enable");
  if (
    !document.fullscreenElement &&
    !document.mozFullScreenElement &&
    !document.webkitFullscreenElement
  ) {
    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen();
    } else if (document.documentElement.mozRequestFullScreen) {
      document.documentElement.mozRequestFullScreen();
    } else if (document.documentElement.webkitRequestFullscreen) {
      document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
    }
  } else {
    if (document.cancelFullScreen) {
      document.cancelFullScreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitCancelFullScreen) {
      document.webkitCancelFullScreen();
    }
  }
};

const initMouseoverListener = () => {
  document.addEventListener("mouseover", handleMouseover);
};

const handleMouseover = (e) => {
  e.stopPropagation();

  const body = document.body;
  const toggleBtn = document.getElementById("toggle_btn");

  if (body.classList.contains("mini-sidebar") && isElementVisible(toggleBtn)) {
    const target = e.target.closest(".sidebar, .header-left");

    if (target) {
      body.classList.add("expand-menu");
      slideDownSubmenu();
    } else {
      body.classList.remove("expand-menu");
      slideUpSubmenu();
    }

    e.preventDefault();
  }
};

const isElementVisible = (element) => {
  return element.offsetWidth > 0 || element.offsetHeight > 0;
};

const slideDownSubmenu = () => {
  const subdropPlusUl = document.getElementsByClassName("subdrop");
  for (let i = 0; i < subdropPlusUl.length; i++) {
    const submenu = subdropPlusUl[i].nextElementSibling;
    if (submenu && submenu.tagName.toLowerCase() === "ul") {
      submenu.style.display = "block";
    }
  }
};

const slideUpSubmenu = () => {
  const subdropPlusUl = document.getElementsByClassName("subdrop");
  for (let i = 0; i < subdropPlusUl.length; i++) {
    const submenu = subdropPlusUl[i].nextElementSibling;
    if (submenu && submenu.tagName.toLowerCase() === "ul") {
      submenu.style.display = "none";
    }
  }
};

onMounted(() => {
  initMouseoverListener();
});

onBeforeUnmount(() => {
  document.removeEventListener("mouseover", handleMouseover);
});
</script>
<style>
.scroll-text-container {
  display: none;
  flex: 3;
  overflow: hidden;
}

.scroll-text {
  white-space: nowrap;
  /* Prevent text from wrapping */
  overflow: hidden;
  /* Hide overflow */
  animation: scroll 10s linear infinite;
  /* Adjust duration as needed */
}

@keyframes scroll {
  0% {
    transform: translateX(100%);
    /* Start off screen */
  }

  100% {
    transform: translateX(-100%);
    /* Scroll to the left */
  }
}
</style>
