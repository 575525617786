<template>
  <div id="app">
    <loader :loading="showLoader" />
    <update-page v-if="showUpdates" />
    <div class="main-wrapper">
      <router-view />
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, onUnmounted, ref, watch } from "vue";
import { useMainStore } from "./store/mainStore";
import { useDashboardStore } from "@/store/dashboardStore";
import { useDevotionStore } from "@/store/devotionStore";
import { useUserStore } from "@/store/userStore";
import { mapStores } from "pinia";
import { usePlanStore } from "./store/planStore";

const mainStore = useMainStore();
const devotionStore = useDevotionStore();
const userStore = useUserStore();
const planStore = usePlanStore();

// const { fetchProfile, fetchUsers, isAuth, fetchAnalysis, fetchDevotions, devotions } = {
//   ...useMainStore(),
//   ...useDashboardStore(),
//   ...useDevotionStore(),
//   ...useUserStore(),
// };

const showUpdates = computed(() => mainStore.showUpdates == true);
const isLoading = ref(false);
const allowLoader = ref(true);
const isAuth = computed(() => mainStore.isAuth)

const showLoader = computed(() => allowLoader.value && isLoading.value)

const handlePendingRequestChanged = (event) => {
  const pendingRequests = event.detail.storage;
  isLoading.value = pendingRequests > 0 ? true : false;
};

function runBackgroundRequests() {
  allowLoader.value = false;
  // planStore.fetchSubscriptionPlans();
  // mainStore.fetchUpdates();

  allowLoader.value = true;
}

async function loadData() {
  // await mainStore.fetchProfile();
  // await devotionStore.fetchDevotions();
  // await userStore.fetchUsers();
  // background requests
  runBackgroundRequests();
}

onMounted(async () => {
  localStorage.setItem("PENDING_REQUESTS", 0);
  window.addEventListener("pendingRequestChanged", handlePendingRequestChanged); // listen for pending requests

  if (mainStore.isAuth) {
    loadData();
  }

});

onUnmounted(() => {
  window.removeEventListener("pendingRequestChanged", handlePendingRequestChanged);
});
</script>
