<template>
    <textarea :id="id"></textarea>
</template>
<script setup>
import { onMounted, ref, watch } from "vue";

const props = defineProps({
    id: String,
    content: String,
    placeholder: String,
    initialContent: String

});

const emit = defineEmits({});


function loadEditor() {
    const summernote = $(`#${props.id}`).summernote({
        height: 300, // set editor height
        minHeight: 300, // set minimum height of editor
        maxHeight: null, // set maximum height of editor
        focus: true, // set focus to editable area after initializing summernote
        placeholder: props.placeholder,
        toolbar: [
            ["style", ["style"]],
            ["font", ["bold", "underline", "clear"]],
            ["fontname", ["fontname"]],
            ["color", ["color"]],
            ["para", ["ul", "ol", "paragraph"]],
            ["table", ["table"]],
            ["insert", ["link", "picture", "video"]],
            ["view", ["undo", "redo", "fullscreen", "codeview", "help"]],
        ],

        callbacks: {
            onInit: function () {
                // Set the initial content when Summernote is initialized
            }
            // onImageUpload: function (files, editor, welEditable) {
            //   sendFile(files[0], textareaID, welEditable);
            // },
            // onMediaDelete: function (target, editor, $editable) {
            //   deleteFile(target[0].src);
            // },
        },
    });

    // Event handler for change in content
    $(summernote).on('summernote.change', function () {
        // Get updated content
        const content = $(this).summernote('code');
        emit("update:content", content);
    });
}
watch(() => props.content, (newValue, oldValue) => {
    // if it's first load
    if (!oldValue) {
        $(`#${props.id}`).summernote('code', props.content);
    }
})
onMounted(() => {
    loadEditor();
})


</script>
<style>
@font-face {
    font-family: "summernote";
    font-style: normal;
    font-weight: 400;
    font-display: auto;
    src: url("@/assets/fonts/summernote/summernote.eot?#iefix") format("embedded-opentype"),
        url("@/assets/fonts/summernote/summernote.woff2") format("woff2"),
        url("@/assets/fonts/summernote/summernote.woff") format("woff"),
        url("@/assets/fonts/summernote/summernote.ttf") format("truetype");
}
</style>