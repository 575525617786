/**
 *
 * @param {string} date
 * @returns
 */
export function dateIsToday(date) {
  var givenDate = new Date(date);
  var currentDate = new Date();

  return (
    givenDate.getFullYear() === currentDate.getFullYear() &&
    givenDate.getMonth() === currentDate.getMonth() &&
    givenDate.getDate() === currentDate.getDate()
  );
}

export function format2RawDate(date) {
  date = new Date(date);
  // Extract the year, month, and day from the date object
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-based
  const day = String(date.getDate()).padStart(2, "0");

  // Concatenate the year, month, and day with a dash to form the formatted date string
  return `${year}-${month}-${day}`;
}
