<template>
  <!-- Add Plan -->
  <div class="modal fade" id="plan-modal">
    <div class="modal-dialog modal-dialog-centered custom-modal-two">
      <div class="modal-content">
        <div class="page-wrapper-new p-0">
          <div class="content">
            <div class="modal-header border-0 custom-modal-header">
              <div class="page-title">
                <h4>{{ props.gym_plan_period_id ? "Update " : "Add " }} Gym Plan</h4>
              </div>
              <button
                type="button"
                class="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body custom-modal-body">
              <form @submit.prevent="submitForm">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="input-blocks">
                      <label>Gym Plan Name</label>
                      <!-- <input
                        type="text"
                        name="name"
                        required
                        v-model="plan.name"
                        :class="{ 'form-control': true }"
                      /> -->
                      <vue-select
                        :options="gyms"
                        placeholder="Choose"
                        v-model="gymPlan.gym_plan_id"
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="input-blocks">
                      <label>Duration</label>
                      <vue-select
                        :options="durations"
                        placeholder="Choose"
                        v-model="gymPlan.gym_period_id"
                      />
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="input-blocks">
                      <label>Price</label>
                      <input
                        type="number"
                        name="price"
                        required
                        v-model="gymPlan.price"
                        :class="{ 'form-control': true }"
                      />
                    </div>
                  </div>
                </div>
                <div class="modal-footer-btn">
                  <button
                    type="button"
                    class="btn btn-cancel me-2"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button type="submit" class="btn btn-submit" id="submit">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Plan -->
</template>
<script setup>
import { usePlanStore } from "@/store/planStore";
import { checkInputValidity } from "@/utils/validator";
import { computed, onMounted, reactive, ref, watch } from "vue";
import { plans } from "@/constants/enum";
// stores
const planStore = usePlanStore();

const props = defineProps({
  gym_plan_period_id: null,
  gym_plan_id: null,
});
// data
const gymPlan = ref({
  gym_plan_id: "",
  price: null,
  gym_period_id: null,
});

async function submitForm() {
  if (props.gym_plan_period_id) {
    // update
    await planStore.updatePlans(props.gym_plan_period_id, gymPlan.value, plans.Gym);
  } else {
    // add
    await planStore.addPlans(gymPlan.value, plans.Gym);
  }
  $("#plan-modal").modal("hide");
  window.location.reload();
}

watch(
  () => gymPlan,
  () => {
    // setTimeout is used here so the plan will be passed to the input b4 this runs
    setTimeout(() => checkInputValidity("submit"), 5);
  },
  { deep: true }
);

watch(
  () => props.gym_plan_period_id,
  () => {
    if (props.gym_plan_period_id) {
      gymPlan.value = planStore.GymPlanDetail.gym_periods.find(
        (plan) => plan.gym_plan_period_id == props.gym_plan_period_id
      );
    } else {
      gymPlan.value = {};
    }
  },
  { immediate: true }
);
const durations = computed(() => {
  return planStore.GymPeriods.map((duration) => {
    return { text: duration.name, id: duration.id };
  });
});

const gyms = computed(() => {
  return planStore.GymList.map((gym) => {
    return { text: gym.name, id: gym.id };
  });
});
onMounted(async () => {
  // if (GymPlanDetail.data.value.length == 0) {
  await planStore.fetchGymPeriods({ type: plans.Gym });
  await planStore.fetchGymList({ type: plans.Gym });
  await planStore.viewMore(props.gym_plan_id, plans.Gym);
  // }
});
</script>
