<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>
  <div class="page-wrapper">
    <div class="content">
      <!-- <GymNavigationButtons /> -->
      <div class="page-header">
        <div class="add-item d-flex">
          <div class="page-title">
            <h4>Dispensed Orders</h4>
          </div>
        </div>
        <ul class="table-top-head">
          <li>
            <input
              type="text"
              v-model="searchQuery"
              class="form-control"
              placeholder="Search orders by user name or reference"
              style="width: 300px"
            />
          </li>
          <!-- <li>
            <a data-bs-toggle="tooltip" data-bs-placement="top" title="Refresh"
              ><i data-feather="rotate-ccw" class="feather-rotate-ccw"></i
            ></a>
          </li> -->
          <li>
            <a
              ref="collapseHeader"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Collapse"
              @click="toggleCollapse(collapseHeader)"
            >
              <i data-feather="chevron-up" class="feather-chevron-up"></i>
            </a>
          </li>
        </ul>
        <!-- <div class="page-btn">
          <a
            href="javascript:void(0);"
            class="btn btn-added"
            @click="openModal('new')"
            ><vue-feather type="plus-circle" class="me-2"></vue-feather>Add To List
          </a>
        </div> -->
      </div>
      <!-- USER TABLE -->
      <!-- {{ ProductList.data }} + -->
      <div class="row">
        <!-- {{ productStore.dispensedOrders }} -->
        <div class="col-xl-12">
          <div class="card">
            <div class="card-header justify-content-between">
              <!-- Secondary -->
              <div class="card-title"></div>
            </div>
            <div class="card-body">
              <div class="accordion accordion-secondary" id="accordionSecondaryExample">
                <a-list
                  item-layout="vertical"
                  size="large"
                  :data-source="filteredOrders"
                  :pagination="{ pageSize: 10 }"
                >
                  <template #renderItem="{ item: order }">
                    <div class="accordion-item" :key="order.order_id">
                      <h2
                        class="accordion-header"
                        :id="'headingPrimary' + order.order_id"
                      >
                        <button
                          style="height: 80px"
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          :data-bs-target="'#collapsePrimary' + order.order_id"
                          aria-expanded="false"
                          :aria-controls="'collapsePrimary' + order.order_id"
                        >
                          <div class="d-flex justify-content-between w-100">
                            <!-- User name -->
                            <div>User Name: {{ order.user }}</div>

                            <!-- Delivery method -->
                            <div class="text-muted">
                              Delivery Method: {{ order.delivery_method }}
                            </div>
                            <div class="text-muted">Reference: {{ order.reference }}</div>

                            <!-- Date -->
                            <div class="text-muted">Date: {{ order.created_at }}</div>

                            <!-- Total amount -->
                            <div class="fw-bold me-3">
                              Total: {{ formatAmount(order.total_amount) }}
                            </div>

                            <!-- <div class="fw-bold me-3">
                              <a
                                class="me-2 confirm-text p-2 mb-0"
                                href="javascript:void(0);"
                                @click="dispenseOrder(order.order_id)"
                                >Dispense
                              </a>
                            </div> -->
                          </div>
                        </button>
                      </h2>

                      <div class="row">
                        <div class="col-md-12">
                          <div
                            :id="'collapsePrimary' + order.order_id"
                            class="accordion-collapse collapse"
                            :aria-labelledby="'headingPrimary' + order.order_id"
                            data-bs-parent="#accordionPrimaryExample"
                          >
                            <!-- USER TABLE -->
                            <filter-table
                              :extra-class="['table', 'datanew']"
                              :columns="OrderList.columns"
                              :data="order.products"
                              :filters="OrderList.filters"
                            >
                              <template #header>
                                <h4>{{ order.user }}</h4>
                              </template>
                              <template #bodyCell="{ column, record }">
                                <template v-if="column.key === 'price'">
                                  {{ formatAmount(record.price) }}
                                </template>

                                <!-- <template v-else-if="column.key === 'action'">
                                  <td class="action-table-data">
                                    <div class="edit-delete-action">
                                      <a
                                        @click="
                                          openModal('update', record.gym_plan_period_id)
                                        "
                                        class="me-2 p-2 mb-0"
                                      >
                                        <i data-feather="edit" class="feather-edit"></i>
                                      </a>
                                      <a
                                        @click="deletePlan(record.gym_plan_period_id)"
                                        class="me-2 confirm-text p-2 mb-0"
                                        href="javascript:void(0);"
                                      >
                                        <i data-feather="trash-2" class="feather-trash-2"></i>
                                      </a>
                                    </div>
                                  </td>
                                </template> -->
                              </template>
                            </filter-table>
                            <!-- USER TABLE -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </a-list>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- USER TABLE -->
    </div>
  </div>
  <ProductModal :product-id="selectedProductId" />
</template>

<script setup>
import GymNavigationButtons from "@/views/layouts/gym-setting.vue";
import ProductModal from "./modal/product-list-modal.vue";
import { computed, onMounted, ref } from "vue";
import Swal from "sweetalert2";
import { useProductStore } from "@/store/productStore";
import { formatAmount } from "@/utils";
import { confirm } from "@/utils/swal";
import { toggleCollapse } from "@/utils/components";
import { plans } from "@/constants/enum";
import { useRouter } from "vue-router";
// store
const productStore = useProductStore();
const router = useRouter();
// data
/**
 * Id of Plan that is being edited
 */
const selectedProductId = ref(null);
const OrderList = {
  filters: ["product_name"],
  columns: [
    {
      title: "Name",
      dataIndex: "product_name",
      key: "product_name",
      sorter: {
        compare: (a, b) => {
          a = a.product_name.toLowerCase();
          b = b.product_name.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Price",
      key: "price",
      sorter: {
        compare: (a, b) => {
          a = a.price.toLowerCase();
          b = b.price.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "quantity",
      dataIndex: "quantity",
      key: "quantity",
      sorter: {
        compare: (a, b) => {
          a = a.quantity.name.toLowerCase();
          b = b.quantity.name.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    // {
    //   title: "Available Quantity",
    //   dataIndex: "available_quantity",
    //   sorter: true,
    // },
    // {
    //   title: "Status",
    //   key: "status",
    //   sorter: true,
    // },
    // {
    //   title: "Action",
    //   key: "action",
    //   sorter: true,
    // },
  ],
  data: computed(() => productStore.dispensedOrders),
};

function viewMore(order_id) {
  productStore.viewMore(order_id);
  // console.log(planStore.GymPlanDetail.gym_periods);
}

const canAddNewPlan = computed(() => productStore.products.length < 3);
// ref elements
const collapseHeader = ref(null);
// Methods
async function deleteProduct(id) {
  confirm({
    title: "Are you sure about this?",
    message: "There might be unfilled orders under for this product",
    callback: async (continueDelete) => {
      if (continueDelete) {
        await productStore.deleteProduct(id);
      }
    },
  });
}

async function dispenseOrder(id) {
  confirm({
    title: "Are you sure all products under this order has been dispensed?",
    // message: "There might be unfilled orders under for this product",
    callback: async (continueDelete) => {
      if (continueDelete) {
        await productStore.dispenseOrder(id);
        await productStore.fetchOrders();
      }
    },
  });
}

const searchQuery = ref(""); // Holds the search query

// Computed property that filters orders based on the search query
const filteredOrders = computed(() => {
  const query = searchQuery.value.toLowerCase();
  return productStore.dispensedOrders.filter(
    (order) =>
      order.user.toLowerCase().includes(query) ||
      order.reference.toLowerCase().includes(query)
  );
});

function openModal(action, id = null) {
  if (action == "update") {
    selectedProductId.value = id;
  } else {
    selectedProductId.value = null;
  }
  $("#plan-modal").modal("show");
}

async function updateProductStatus(isActive, userId) {
  await productStore.updateProductStatus(userId, {
    status: isActive ? "active" : "inactive",
  });
  await productStore.fetchProducts();
}

onMounted(async () => {
  // if (OrderList.data.value.length == 0) {
  await productStore.fetchDispensedOrders(1);
  // }
});
</script>
