<template>
    <div :class="`justify-content-center img-upload ${$props.class}`"
        :style="{ '--IMG-HEIGHT': `${height}px`, '--IMG-WIDTH': `${width}px` }">
        <input type="file" @change="onFileChange" />
        <div class="h-100 d-flex align-items-center" @click="onProfilePicClick">
            <img v-if="imagePreviewUrl" :src="imagePreviewUrl" />
            <span v-else>
                <i class="d-block text-center">Click to Add Photo</i>
            </span>

        </div>
    </div>
</template>

<script setup>
import { Image } from 'ant-design-vue';
import { ref, watch } from 'vue';

const emit = defineEmits({
    "update:file": (file) => file instanceof File
});
const props = defineProps({
    class: null,
    height: { type: Number, default: 120 },
    width: { type: Number, default: 120 },
    src: { type: String, default: null }
})
const imagePreviewUrl = ref(null);

const onFileChange = (event) => {
    const file = event.target.files[0];

    let reader = new FileReader();
    reader.onload = () => {
        imagePreviewUrl.value = reader.result;
    };
    emit("update:file", file);
    reader.readAsDataURL(file);
    // Handle file change here
};

const onProfilePicClick = () => {
    // Handle profile picture click here
};

watch(() => props.src, () => {
    imagePreviewUrl.value = props.src;
});

</script>
<style scoped>
.img-upload {
    /* --IMG-HEIGHT: 120px; */
    /* --IMG-WIDTH: 120px; */
    width: var(--IMG-WIDTH);
    height: var(--IMG-HEIGHT);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    border: 1px dashed gray;
}

.img-upload img {
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.img-upload input[type='file'] {
    left: 0;
    top: 0;
    cursor: pointer;
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0;
}
</style>