import { api } from "@/configs";
import { defineStore } from "pinia";
import { apiUrls } from "@/constants";
import Swal from "sweetalert2";
import { devotionTypes } from "@/constants/enum";
import { reloadDevotions } from "@/utils/devotion";

export const useCommentStore = defineStore("comment", {
  state: () => {
    return {
      comments: {},
    };
  },
  getters: {
    loadDevotionComments: (state) => (devotionId) => {
      return state.comments[devotionId] ?? [];
    },
  },
  actions: {
    async addComment(devotionId, comment = { content, parent_comment_id }) {
      try {
        await api.post(`${apiUrls.comments}`, {
          ...comment,
          devotion_id: devotionId,
        });

        Swal.fire(`Comment Added`, "", "success");
        return true;
      } catch (error) {
        return false;
      }
    },
    async fetchComments(devotionId) {
      try {
        const response = await api.get(`${apiUrls.comments}/${devotionId}`);
        return response.data.data;
      } catch (error) {
        return false;
      }
    },
    async deleteComment(commentId) {
      try {
        const response = await api.delete(`${apiUrls.comments}/${commentId}`);
        return true;
      } catch (error) {
        return false;
      }
    },
  },
});
