<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>
  <div class="page-wrapper">
    <div class="content">
      <div class="page-header">
        <div class="add-item d-flex">
          <div class="page-title">
            <h4>Devotion Category List</h4>
            <!-- {{ Categories.data }} -->
            <!-- <h6>Manage Your Subscription Plans</h6> -->
          </div>
        </div>
        <ul class="table-top-head">
          <!-- <li>
            <a data-bs-toggle="tooltip" data-bs-placement="top" title="Refresh"
              ><i data-feather="rotate-ccw" class="feather-rotate-ccw"></i
            ></a>
          </li> -->
          <li>
            <a
              ref="collapseHeader"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Collapse"
              @click="toggleCollapse(collapseHeader)"
            >
              <i data-feather="chevron-up" class="feather-chevron-up"></i>
            </a>
          </li>
        </ul>
        <div class="page-btn">
          <a
          v-if="canAddNewDevotionCategory"
            href="javascript:void(0);"
            class="btn btn-added"
            @click="openModal('new')"
            ><vue-feather type="plus-circle" class="me-2"></vue-feather>Add New Devotion Category
          </a>
        </div>
      </div>
      <!-- USER TABLE -->
      <filter-table
        :extra-class="['table', 'datanew']"
        :columns="Categories.columns"
        :data="Categories.data"
        :filters="Categories.filters"
      >
        <template #header>
          <h4>Available Categories</h4>
        </template>
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'action'">
            <td class="action-table-data">
              <div class="edit-delete-action">
                <a @click="openModal('update', record.id)" class="me-2 p-2 mb-0">
                  <i data-feather="edit" class="feather-edit"></i>
                </a>
                <a
                  @click="deleteCategory(record.id)"
                  class="me-2 confirm-text p-2 mb-0"
                  href="javascript:void(0);"
                >
                  <i data-feather="trash-2" class="feather-trash-2"></i>
                </a>
              </div>
            </td>
          </template>
          </template>
        
        <!-- </template> -->
      </filter-table>
      <!-- USER TABLE -->
    </div>
  </div>
  <DevotionCatModel :category-id="selectedCategoryId" ref="devotionCatModal"/>
</template>

<script setup>
import DevotionCatModel from "./modal/devotion-category-modal.vue";
import { computed, onMounted, ref } from "vue";
import Swal from "sweetalert2";
// import { usePlanStore } from "@/store/planStore";
import { useDevotionStore } from "@/store/devotionStore";
import { formatAmount } from "@/utils";
import { confirm } from "@/utils/swal";
import { toggleCollapse } from "@/utils/components";
// store

// const categories = computed(() =>
//   devotionStore.devotionCategories.map((category) => ({
//     id: category.id,
//     text: category.name,
//   }))
// );
// const planStore = usePlanStore();
const devotionStore = useDevotionStore();

// data
/**
 * Id of Plan that is being edited
 */
const selectedCategoryId = ref(null);
const Categories = {
  filters: ["name"],
  columns: [
    {
      title: "Devotion Category",
      dataIndex: "name",
      key: "name",
      sorter: {
        compare: (a, b) => {
          a = a.name.toLowerCase();
          b = b.name.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      sorter: {
        compare: (a, b) => {
          a = a.description.toLowerCase();
          b = b.description.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Created On",
      dataIndex: "created_on",
      key: "created_on",
      sorter: true,
    },
    {
      title: "Action",
      key: "action",
      sorter: true,
    },
  ],
  data: computed(() => devotionStore.devotionCategories),
};

const canAddNewDevotionCategory = computed(() => devotionStore.devotionCategories.length < 3);
// ref elements
const collapseHeader = ref(null);
const devotionCatModal = ref(null);
// Methods
async function deleteCategory(id) {
  confirm({
    title: "Are you sure about this?",
    message: "This category and all its devotions attached to it will be deleted",
    callback: async (continueDelete) => {
      if (continueDelete) {
        await devotionStore.deleteDevotionCategory(id);
      }
    },
  });
}

function openModal(action, id = null) {
  if (action == "update") {
    selectedCategoryId.value = id;
  } else {
    selectedCategoryId.value = null;
  }
  $("#devotionCatModal").modal("show");
}

onMounted(async () => {
  if (Categories.data.value.length == 0) {
    await devotionStore.fetchDevotionCategories();
  }
});
</script>
