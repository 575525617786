import { devotionTypes } from "@/constants/enum";

/**
 * This is for encoding a devotion data sent through the url
 * @param {*} devotion
 * @returns
 */
export function encodeUrlData(type, date, devotionId = null, ...extra) {
  return btoa(JSON.stringify({ type, date, devotionId, ...extra }));
}

/**
 * Decode the Data
 * @param {String} encodedData
 * @returns {Object}
 * ```json
 * {
 *    "type":"string",
 *    "date":"string",
 *  }
 * ```
 */
export const decodeUrlData = (encodedData) => {
  try {
    const data = JSON.parse(atob(encodedData));
    return {
      type: data.type,
      date: data.date,
      ...data,
    };
  } catch (error) {
    log("Error decoding data:", error);
    return null; // or handle the error in an appropriate way
  }
};

export const years = () => {
  const currentYear = new Date().getFullYear();
  const startYear = 2016;
  const years = [];
  for (let year = startYear; year <= currentYear; year++) {
    years.push(year);
  }
  return years;
};
