import axios from "axios";
import { router } from "@/router";
import { getAccessToken, updatePendingRequest } from "@/utils";
import Swal from "sweetalert2";
import { apiUrls } from "@/constants";

function showSwal(url, params = []) {
  const shouldShowSwal = [`${apiUrls.user}/search`].filter((path) => url?.includes(path));

  const shouldShowSwalParams = ["search_query"].filter((path) =>
    Object.keys(params ?? {}).includes(path)
  );
  return !shouldShowSwal.length && !shouldShowSwalParams.length;
}

function getAuthorizationHeader() {
  return `Bearer ${getAccessToken()}`;
}

const api = axios.create({
  validateStatus: function (status) {
    if (status == 401) {
      if (getAccessToken() == null) {
        Swal.fire("Access Denied", "", "error");
        return false;
      }
      return false;
    } else if (status == 200 || status == 201) {
      return true;
    }
    return false;
  },
});

function handleError(error) {
  const { config, response } = error;
  if (showSwal(config?.url)) {
    updatePendingRequest(false);
  }

  const message = response?.data?.message;

  if (message && message.includes("authenticated")) {
    Swal.fire("Sorry, you need to log in as an admin again", "", "error");
    localStorage.clear();
    router.push("/");
  } else if (message) {
    Swal.fire({ title: message, icon: "error" });
  } else {
    Swal.fire({
      title: "Please Check Your Network Connectivity",
      icon: "error",
    });
  }

  throw error;
}

/**
 * This Handles the Authentication Properly
 */
api.interceptors.response.use(
  function (config) {
    if (showSwal(config?.config?.url)) {
      updatePendingRequest(false);
    }
    return config;
  },
  function (error) {
    handleError(error);
  }
);

api.interceptors.request.use(
  function (request) {
    if (showSwal(request?.url)) {
      updatePendingRequest();
    }
    request.headers.Authorization = getAuthorizationHeader();
    return request;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export { api };
