<template>
  <!-- Add User -->
  <Modal title="User" ref="userModal" @opened="initData">
    <div id="userDetail">
      <div class="row">
        <div class="col-lg-12 d-flex justify-content-center mb-5">
          <ImageUpload
            class="rounded-circle"
            v-model:file="user.profile_picture"
            :src="user.profile_picture_preview"
          />
          <Avatar
            img-class="user-image"
            class="rounded-circle"
            v-model:file="user.profile_picture"
            :src="user.profile_picture_preview"
          />
        </div>
        <div class="col-lg-6">
          <div class="input-blocks">
            <label>First Name</label>
            <input
              type="text"
              class="form-control"
              required
              name="first_name"
              :disabled="action == actions.VIEW"
              v-model="user.first_name"
            />
          </div>
        </div>
        <div class="col-lg-6">
          <div class="input-blocks">
            <label>Last Name</label>
            <input
              type="text"
              class="form-control"
              required
              name="last_name"
              :disabled="action == actions.VIEW"
              v-model="user.last_name"
            />
          </div>
        </div>
        <!-- <div class="col-lg-6">
          <div class="input-blocks">
            <label>Account Type</label>
            <vue-select :options="accountTypes" id="listus" placeholder="Choose" :disabled="action != actions.CREATE"
              v-model="user.account_type" required name="accountType" />
          </div>
        </div>
        <div class="col-lg-6">
          <div class="input-blocks">
            <label>Dail Code</label>
            <div class="d-flex">
              <vue-select class="w-100" id="dailCodes" :options="dailCodes" required name="dail_code"
                :disabled="action == actions.VIEW" v-model="user.dail_code" />
            </div>
          </div>
        </div> -->
        <div class="col-lg-6">
          <div class="input-blocks">
            <label>Mobile Number</label>
            <input
              type="number"
              :minlength="9"
              class="form-control"
              required
              name="mobile_number"
              :disabled="action == actions.VIEW"
              v-model="user.phone"
            />
          </div>
        </div>

        <div class="col-lg-6">
          <div class="input-blocks">
            <label>Email</label>
            <input
              type="email"
              class="form-control"
              required
              name="email"
              :disabled="action == actions.VIEW"
              v-model="user.email"
            />
          </div>
        </div>

        <div class="col-lg-6">
          <div class="input-blocks">
            <label>Entry Card Number</label>
            <input
              type="text"
              class="form-control"
              required
              name="card"
              :disabled="action == actions.VIEW"
              v-model="user.card_number"
            />
          </div>
        </div>

        <!-- <div v-if="action == actions.VIEW">
          <h4 class="mt-5 mb-3">
            Subscription Detail
          </h4>
          <div class="col-lg-12">
            <div class="input-blocks">
              <label>Current Subscription</label>
              <input class="form-control" disabled :value="user.subscription.name || 'None'" />
            </div>
          </div>

          <div class="row col-12 px-0 mx-0" v-if="user.subscription.name">
            <div class="col-lg-6">
              <div class="input-blocks">
                <label>Status</label>
                <input class="form-control" disabled :value="user.subscription.status || 'None'" />
              </div>
            </div>
            <div class="col-lg-6">
              <div class="input-blocks">
                <label>Auto Renews</label>
                <input class="form-control" disabled v-model="user.subscription.auto_renews" />
              </div>
            </div>
            <div class="col-lg-6">
              <div class="input-blocks">
                <label>Created At</label>
                <input class="form-control" disabled :value="formatDate(user.subscription.created_at)" />
              </div>
            </div>
            <div class="col-lg-6">
              <div class="input-blocks">
                <label>Expires At</label>
                <input class="form-control" disabled :value="formatDate(user.subscription.expires_at)" />
              </div>
            </div>
          </div>

        </div> -->
      </div>
      <div class="modal-footer-btn" :hidden="action == actions.VIEW">
        <button type="button" class="btn btn-cancel me-2" @click="userModal.closeModal()">
          Cancel
        </button>
        <button type="submit" class="btn btn-submit" id="submit" @click="saveUser()">
          Submit
        </button>
      </div>
    </div>
  </Modal>
  <!-- /Add User -->
</template>
<script setup>
import countries from "@/assets/json/country_codes.json";
import { computed, onMounted, ref, watch } from "vue";
import ImageUpload from "../components/image-upload.vue";
import { useUserStore } from "@/store/userStore";
import { checkInputValidity } from "@/utils/validator";
import Modal from "@/components/modal/modal.vue";
import { formatDate } from "@/utils";

// stores
const userStore = useUserStore();

// refs
const userModal = ref(null);
const actions = {
  VIEW: "View",
  CREATE: "Create",
  UPDATE: "Update",
};

// props
const props = defineProps({
  userId: String,
  action: {
    type: String,
    required: true,
  },
});

// computed
const isUpdate = computed(() => props.userId);
const showPassword = ref(false);
const dailCodes = computed(() => {
  return countries.map((country) => ({
    text: `${country.dail_code} ${country.name}`,
    id: country.dail_code,
  }));
});

const user = ref({
  email: null,
  name: "",
  first_name: null,
  last_name: null,
  // dail_code: null,
  phone: null,
  // account_type: null,
  // is_admin: null,
  // password: null,
  profile_picture: null,
  profile_picture_preview: null,
  subscription: {},
  card_number: null,
});

watch(
  user,
  () => {
    setTimeout(() => checkInputValidity("submit", "userDetail"), 5);
  },
  { deep: true }
);

watch(
  () => props.userId,
  () => {
    if (props.userId) {
      fillData({ ...userStore.users.find((user) => user.id == props.userId) });
    } else {
      resetData();
    }
  },
  { immediate: true }
);

const accountTypes = ["Subscriber", "Administrator"];

async function saveUser() {
  let response = false;

  const data = {
    email: user.value.email,
    name: user.value.first_name + " " + user.value.last_name,
    // dail_code: user.value.dail_code,
    mobile_number: user.value.phone,
    // is_admin: user.value.account_type == 'Administrator' ? 1 : 0,
    card_number: user.value.card_number,
    profile_picture: user.value.profile_picture,
  };
  if (props.action == actions.UPDATE) {
    response = await userStore.updateUser(props.userId, data);
  } else {
    response = await userStore.addUser(data);
  }
  response && userModal.value.closeModal();
}

function resetData() {
  user.value.email = null;
  user.value.first_name = null;
  user.value.last_name = null;
  user.value.dail_code = null;
  user.value.phone = null;
  user.value.account_type = null;
  user.value.is_admin = null;
  user.value.password = null;
  user.value.profile_picture = null;
  user.value.profile_picture_preview = null;
  user.value.subscription = {};
}
function fillData(data) {
  user.value = data;
  user.value.account_type = data.is_admin ? "Administrator" : "Subscriber";
  [user.value.first_name, user.value.last_name] = data.name.split(" ");
  user.value.profile_picture_preview = data.profile_picture;
}

function initData() {
  if (!props.userId) {
    resetData();
  }
  setTimeout(() => checkInputValidity("submit", "userDetail"), 5);
}
onMounted(() => {});

// Expose methods
defineExpose({ openModal: () => userModal.value.openModal() });
</script>
