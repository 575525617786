<template>
  <!-- Add user -->
  <div class="modal fade" id="transaction-history-modal">
    <div class="modal-dialog modal-dialog-centered custom-modal-two">
      <div class="modal-content">
        <div class="page-wrapper-new p-0">
          <div class="content">
            <div class="modal-header border-0 custom-modal-header">
              <div class="page-title">
                <h4 style="margin-left: 17px;">{{ props.selected_name }} ({{props.selected_account_number}})</h4>
              </div>
              <button
                type="button"
                class="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body custom-modal-body">
              <filter-table
                :extra-class="['table', 'datanew']"
                :columns="userTransaction.columns"
                :data="userTransaction.users"
              >
                <template #header>
                    <h4 style="margin-bottom: 15px;">Balance: ₦{{ props.selected_balance }}</h4>
                    <!-- <h4 style="float:right">{{props.selected_account_number}}</h4>   -->
                </template>
                {{ user }}
                <template #bodyCell="{ column, record }">
                  <template v-if="column.key === 'name'">
                    <div class="userimgname gap-2 align-items-center">
                      <div>
                        <a href="javascript:void(0);">{{ record.name }}</a>
                      </div>
                    </div>
                  </template>
                  <template v-else-if="column.key === 'status'">
                    <a-switch
                      :checked="record.is_active"
                      @change="updateUserStatus($event, record.id)"
                    />
                  </template>
                  <!-- <template v-else-if="column.key === 'subscription'">
                    <span :class="getBadgeClass(record.subscription)"
                      >{{ record.subscription }}</span
                    >
                  </template> -->
                  <template v-if="column.key === 'account_number'">
                    <a
                      href="javascript:void(0);"
                      @click="openHistoryModal(record.account_number)"
                      style="color: red;"
                      >{{ record.account_number }}</a
                    >
                  </template>
                  <!-- <template v-else-if="column.key === 'action'">
                    <td class="action-table-data">
                      <div class="edit-delete-action">
                        <a
                          class="me-2 p-2 mb-0"
                          href="javascript:void(0);"
                          @click="openModal('View', record.id)"
                        >
                          <vue-feather type="eye" class="action-eye"></vue-feather>
                        </a>
                        <a class="me-2 p-2 mb-0" @click="openModal('Update', record.id)">
                          <i data-feather="edit" class="feather-edit"></i>
                        </a>
                        <a
                          class="me-2 confirm-text p-2 mb-0"
                          href="javascript:void(0);"
                          @click="deleteUser(record.id)"
                          ><i data-feather="trash-2" class="feather-trash-2"></i>
                        </a>
                        <a
                          class="me-2 confirm-text p-2 mb-0"
                          href="javascript:void(0);"
                          @click="creditModal(record.account_number, 'action')"
                          ><i data-feather="plus" class="feather-plus"></i>
                        </a>
                      </div>
                    </td>
                  </template> -->
                </template>
              </filter-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Plan -->
</template>
<script setup>
import { useUserStore } from "@/store/userStore";
import { checkInputValidity } from "@/utils/validator";
import { computed, onMounted, reactive, ref, watch } from "vue";
// stores
const userStore = useUserStore();

const userTransaction = ref({
  filters: ["name", "email"],
  columns: ref([
    {
      title: "Reference",
      dataIndex: "reference",
      key: "reference",
      sorter: {
        compare: (a, b) => {
          a = a.reference.toLowerCase();
          b = b.reference.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      sorter: {
        compare: (a, b) => {
          a = a.amount.toLowerCase();
          b = b.amount.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Purpose",
      dataIndex: "purpose",
      sorter: {
        compare: (a, b) => {
          a = a.purpose.toLowerCase();
          b = b.purpose.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: {
        compare: (a, b) => {
          a = a.status.toLowerCase();
          b = b.status.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      sorter: {
        compare: (a, b) => {
          a = a.type.toLowerCase();
          b = b.type.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Method",
      dataIndex: "method",
      key: "method",
      sorter: {
        compare: (a, b) => {
          a = a.method.toLowerCase();
          b = b.method.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Admin",
      dataIndex: "admin_name",
      key: "admin_name",
      sorter: {
        compare: (a, b) => {
          a = a.admin_name.toLowerCase();
          b = b.admin_name.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      sorter: {
        compare: (a, b) => {
          a = a.created_at.toLowerCase();
          b = b.created_at.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    // {
    //   title: "Action",
    //   key: "action",
    //   sorter: true,
    // },
  ]),
  users: computed(() => userStore.userTransactionHistory),
  selectedUserId: ref(null),
});

const props = defineProps({
  selected_user_id: null,
  selected_account_number: null,
  selected_balance: null,
  selected_name: null,
  action: null,
  
});
// data
const user = ref({
  account_number: null,
  userName: null,
});

watch(
  () => user,
  () => {
    // setTimeout is used here so the user will be passed to the input b4 this runs
    setTimeout(() => checkInputValidity("submit"), 5);
  },
  { deep: true }
);

watch(
  () => props.selected_user_id,
  () => {
    if (props.selected_user_id) {
      user.value = userStore.users.find(
        (user) => user.id == props.selected_user_id
      );
    } else {
      user.value = {};
    }
  },
  { immediate: true }
);

onMounted(async () => {
  // await userStore.fetchUserTransactionHistory(props.account_number);
});
</script>

<style scoped>
.custom-modal-two {
  max-width: 80%; /* Set this to a desired percentage or fixed width */
  width: 80%; /* Optional: ensure it takes the full width of max-width */
}

@media (max-width: 768px) {
  .custom-modal-two {
      max-width: 90%; /* Adjust for smaller screens if needed */
  }
}

</style>
