<template>
    <div :class="`input-switch d-flex ${$props.class}`" :style="{ '--ACCENT-COLOR': accentColor }">
        <b-form-input step="any" :required="required" :disabled="disabled" v-model="internalValue" class="siwtch-input"
            min="0" :placeholder="label" :name="name" />
        <a-switch @change="checkUpdated" :disabled="checkDisabled" v-model:checked="internalChecked" class="switch" />
    </div>
</template>
<script setup>
import { ref, watch } from 'vue';

const emit = defineEmits({
    "update:value": Boolean,
    "update:checked": Boolean,
    "checkUpdated": Boolean,
});
const props = defineProps({
    label: String,
    value: String,
    name: String,
    class: String,
    checked: Boolean,
    required: Boolean,
    checkDisabled: {
        type: Boolean,
        default: true
    },
    accentColor: {
        type: String,
        default: '#1677ff'
    },
    disabled: Boolean
});
const internalValue = ref(props.value);
const internalChecked = ref(props.checked);

watch(() => props.value, (newValue) => {
    internalValue.value = newValue;
}, { immediate: true });

watch(internalValue, (newInternalValue) => {
    emit('update:value', newInternalValue);
});

watch(() => props.checked, (newValue) => {
    internalChecked.value = newValue;
});
watch(internalChecked, (newinternalChecked) => {
    emit('update:checked', newinternalChecked);
});

function checkUpdated(value) {
    emit('checkUpdated', value);
}

</script>
<style>
.input-switch .switch {
    border-radius: 0 0.372rem 0.372rem 0 !important;
    height: auto !important;
}

.input-switch .siwtch-input {
    border-radius: 0.372rem 0 0 0.372rem !important;
}

.input-switch .switch .ant-switch-handle::before {
    border-radius: 0.372rem !important;
}

.input-switch .switch .ant-switch-handle {
    height: 90% !important;
}

.input-switch .ant-switch.ant-switch-checked {
    background: var(--ACCENT-COLOR);
}
</style>