import { api } from "@/configs";
import { defineStore } from "pinia";
import { apiUrls } from "@/constants";

export function processUser(user) {
  return {
    ...user,
    status: user.is_active ? "Active" : "Inactive",
    is_active: !!user.is_active,
    subscription_name: user.subscription.name ?? "None",
  };
}
export const useUserStore = defineStore("user", {
  state: () => {
    return {
      users: [],
      userTransactionHistory: [],
      singleUserQuickView: {},
    };
  },
  actions: {
    async fetchUsers() {
      try {
        const response = await api.get(apiUrls.user);
        this.users = response.data.data.map((user) => processUser(user));
      } catch (error) {}
    },
    async addUser(user) {
      try {
        await api.post(apiUrls.user, user, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        await this.fetchUsers();
        Swal.fire("User Added Successfully", "", "success");
        return true;
      } catch (error) {
        return false;
      }
    },
    async updateUserStatus(id, user) {
      try {
        const response = await api.post(
          `${apiUrls.user}/${id}`,
          { _method: "PATCH", ...user },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        // update the use to skip fetching all user again
        const updatedUserIndex = this.users.findIndex((user) => user.id == id);
        this.users[updatedUserIndex] = processUser(response.data.data);
        Swal.fire(`User Updated Successfully`, "", "success");
        return true;
      } catch (error) {
        log(error);
        return false;
      }
    },
    async deleteUser(userId) {
      try {
        await api.delete(`${apiUrls.user}/${userId}`);

        const deletedUserIndex = this.users.findIndex(
          (user) => user.id == userId
        );
        delete this.users[deletedUserIndex];
        Swal.fire(`User Deleted Successfully`, "", "success");
        return true;
      } catch (error) {
        return false;
      }
    },

    async fetchUserTransactionHistory(userId) {
      try {
        const response = await api.get(
          `${apiUrls.user}/${userId}/transactionHistory`
        );
        this.userTransactionHistory = response.data.data;
      } catch (error) {}
    },

    async userQuickGlance(userId) {
      try {
        const response = await api.get(
          `${apiUrls.user}/quick-view/${userId}`
        );
        this.singleUserQuickView = response.data.data;
      } catch (error) {}
    },

    async searchUserUsingAccountNumber(account_number) {
      try {
        const response = await api.get(
          `${apiUrls.user}/search/${account_number}`
        );
        if (response.data.status) return response.data.data.name;
        else return data.message;
      } catch (error) {
        console.log(error);
      }
    },

    async sendFundUserOtp(account_number, amount) {
      try {
        const fund = { account_number, amount };
        const response = await api.post(`${apiUrls.user}/sendFundOtp`, fund);
        if (response.data.status) {
          await this.fetchUsers();
          return response.data.status;
        } else return data.status;
      } catch (error) {
        console.log(error);
      }
    },

    async creditAccount(otp, account_number) {
      try {
        const response = await api.post(
          `${apiUrls.user}/credit/${account_number}`,
          otp
        );
        if (response.data.status) return response.data.status;
        else return response.data.status;
      } catch (error) {
        console.log(error);
      }
    },

    async changePassword() {
      try {
        const response = await api.post(`${apiUrls.user}/changePassword`);
        if (response.data.status) return response.data.status;
        else return response.data.status;
      } catch (error) {
        console.log(error);
      }
    },

    async makeAdmin(id) {
      try {
        const response = await api.post(`${apiUrls.user}/convert/${id}`);
        if (response.data.status) {
          return response.data.status;
        } else {
          return response.data.status;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
});
