<template>
  <!-- Add Plan -->
  <div class="modal fade" id="restock-modal">
    <div class="modal-dialog modal-dialog-centered custom-modal-two">
      <div class="modal-content">
        <div class="page-wrapper-new p-0">
          <div class="content">
            <div class="modal-header border-0 custom-modal-header">
              <div class="page-title">
                <h4>Restock </h4>
                <!-- {{ products }} -->
              </div>
              <button
                type="button"
                class="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body custom-modal-body">
              <form @submit.prevent="submitForm">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="input-blocks">
                      <label>Quantity</label>
                      <input
                        type="number"
                        name="name"
                        required
                        v-model="restock.quantity"
                        :class="{ 'form-control': true }"
                      />
                    </div>
                  </div>
                </div>

                <div class="modal-footer-btn">
                  <button
                    type="button"
                    class="btn btn-cancel me-2"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button type="submit" class="btn btn-submit" id="submit">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Plan -->
</template>
<script setup>
import { checkInputValidity } from "@/utils/validator";
import { computed, onMounted, reactive, ref, watch } from "vue";
import { useProductStore } from "@/store/productStore";
// stores
const productStore = useProductStore();

const props = defineProps({
  productId: null,
});
// data
const restock = reactive({
  quantity: null,
  productId: props.productId,
});

async function submitForm() {
  if (props.productId) {
    restock.productId = props.productId;
    await productStore.restock(restock);
  } else {
    await productStore.updateProduct(props.productId, products);
    // add
  }
  $("#restock-modal").modal("hide");
}

watch(
  () => restock,
  () => {
    // setTimeout is used here so the service will be passed to the input b4 this runs
    setTimeout(() => checkInputValidity("submit"), 5);
  },
  { deep: true }
);

// watch(
//   () => props.productId,
//   (newServiceId) => {
//     imageUrls.value = [];
//     if (props.productId) {
//       const existingProduct = productStore.products.find(
//         (product) => product.id == newServiceId
//       );
//       if (existingProduct) {
//         Object.assign(products, existingProduct);
//         products.images = [];
//         // products.images = existingProduct.images;
//         products.category_id = existingProduct.category.id;
//         imageUrls.value = existingProduct.images;
//       }
//     } else {
//       Object.assign(restock, {
//         quantity: "",
//       });
//     }
//   },
//   { immediate: true }
// );

onMounted(() => {});
</script>

<style scoped>
/* Styling for image previews */
.image-preview {
  position: relative;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
}

.image-preview img {
  max-width: 150px;
  max-height: 150px;
  border-radius: 8px;
}

.remove-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  color: rgb(247, 7, 7);
  border: none;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  cursor: pointer;
  font-size: 14px;
}

/* Custom file input styling */
.custom-file {
  position: relative;
  display: inline-block;
}

.custom-file-input {
  display: none; /* Hide the default file input */
}

.custom-file-label {
  display: inline-block;
  padding: 10px 20px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  background-color: #ffffff;
  color: #495057;
  cursor: pointer; /* Change cursor to pointer on hover */
  transition: background-color 0.3s, border-color 0.3s;
}

/* Hover and focus effects */
.custom-file-label:hover {
  background-color: #f8f9fa; /* Light gray on hover */
  border-color: #80bdff; /* Blue border on hover */
}

.custom-file-label:focus {
  outline: none; /* Remove outline on focus */
  border-color: #80bdff; /* Blue border on focus */
}
</style>
