<template>
  <div class="sidebar" id="sidebar">
    <div class="sidebar-inner slimscroll">
      <perfect-scrollbar class="scroll-area" :settings="settings" @ps-scroll-y="scrollHanle">
        <div id="sidebar-menu" class="sidebar-menu">
          <user-menu></user-menu>
        </div>
        <!-- <div style="padding: 24px; bottom: 25px; font-size: 10px; font-family: 'Nunito';" class="position-absolute">
          <span>ProLife Fitness Dashboard</span>
          <br>
          <div style="color: var(--PRIMARY-COLOR);cursor:pointer" @click="showUpdates()" v-if="hasUpdate">View Updates
          </div>
          <a>V {{ version }}</a><br>
        </div> -->
      </perfect-scrollbar>
    </div>
  </div>
  <collapsed-sidebar></collapsed-sidebar>
  <horizontal-sidebar></horizontal-sidebar>
</template>

<script setup>
import { version } from "@/constants";
import { ref, computed } from "vue";
import { PerfectScrollbar } from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
import { useMainStore } from "@/store/mainStore";

const mainStore = useMainStore();

const hasUpdate = computed(() => mainStore.updates.length > 0);
const settings = ref({ suppressScrollX: true });

function scrollHanle(evt) { };
function showUpdates() {
  mainStore.showUpdates = true;
};
</script>
