<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>
  <div class="page-wrapper">
    <div class="content">
      <!-- <GymNavigationButtons /> -->
      <div class="page-header">
        <div class="add-item d-flex">
          <div class="page-title">
            <h4>Splash Screen List</h4>
            <!-- <h6>Manage Your Products</h6> -->
          </div>
        </div>
        <ul class="table-top-head">
          <!-- <li>
            <a data-bs-toggle="tooltip" data-bs-placement="top" title="Refresh"
              ><i data-feather="rotate-ccw" class="feather-rotate-ccw"></i
            ></a>
          </li> -->
          <li>
            <a
              ref="collapseHeader"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Collapse"
              @click="toggleCollapse(collapseHeader)"
            >
              <i data-feather="chevron-up" class="feather-chevron-up"></i>
            </a>
          </li>
        </ul>
        <div class="page-btn">
          <a href="javascript:void(0);" class="btn btn-added" @click="openModal('new')"
            ><vue-feather type="plus-circle" class="me-2"></vue-feather>Add To List
          </a>
        </div>
      </div>
      <!-- USER TABLE -->
      <!-- {{ ProductList.data }} + -->
      <filter-table
        :extra-class="['table', 'datanew']"
        :columns="SplashScreen.columns"
        :data="SplashScreen.data"
        :filters="SplashScreen.filters"
      >
        <template #header>
          <!-- <h4>Available Products</h4> -->
        </template>
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'category'">{{ record.category.name }}</template>
          <template v-if="column.key === 'available_quantity'">
            <a href="#" @click="openRestockHistory(record.id)">{{
              record.available_quantity
            }}</a>
          </template>
          <template v-else-if="column.key === 'status'">
            <a-switch
              :checked="record.is_active"
              @change="updateSplashScreenStatus(record.id)"
            />
          </template>
          <template v-if="column.key === 'action'">
            <td class="action-table-data">
              <div class="edit-delete-action">
                <a @click="openModal('update', record.id)" class="me-2 p-2 mb-0">
                  <i data-feather="edit" class="feather-edit"></i>
                </a>
                <a
                  @click="deleteSplashScreen(record.id)"
                  class="me-2 confirm-text p-2 mb-0"
                  href="javascript:void(0);"
                >
                  <i data-feather="trash-2" class="feather-trash-2"></i>
                </a>
              </div>
            </td>
          </template>
        </template>
      </filter-table>
      <!-- USER TABLE -->
    </div>
  </div>
  <SplashScreenModal :splash-screen-id="selectedSplashId" />
</template>

<script setup>
import SplashScreenModal from "./modal/splashscreen-list-modal.vue";
import { computed, onMounted, ref } from "vue";
import { useAdminStore } from "@/store/adminStore";
import { confirm } from "@/utils/swal";
import { toggleCollapse } from "@/utils/components";
// store
const adminStore = useAdminStore();
// data
/**
 * Id of Plan that is being edited
 */
const selectedSplashId = ref(null);
const selectedProductQuantity = ref(null);
const SplashScreen = ref({
  filters: ["title"],
  columns: [
    {
      title: "Title",
      dataIndex: "title",
      sorter: {
        compare: (a, b) => {
          a = a.name.toLowerCase();
          b = b.name.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      sorter: {
        compare: (a, b) => {
          a = new Date(a.start_date);
          b = new Date(b.start_date);
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      sorter: {
        compare: (a, b) => {
          a = new Date(a.end_date);
          b = new Date(b.end_date);
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Status",
      key: "status",
      sorter: true,
    },
    {
      title: "Action",
      key: "action",
      sorter: true,
    },
  ],
  data: computed(() => adminStore.splashscreen),
});

const canAddNewPlan = computed(() => adminStore.products.length < 3);
// ref elements
const collapseHeader = ref(null);
// Methods
async function deleteSplashScreen(id) {
  confirm({
    title: "Are you sure about this?",
    callback: async (continueDelete) => {
      if (continueDelete) {
        await adminStore.deleteSplashScreen(id);
      }
    },
  });
}

function openModal(action, id = null) {
  if (action == "update") {
    selectedSplashId.value = id;
  } else {
    selectedSplashId.value = null;
  }
  $("#splash-modal").modal("show");
}

function restockModal(productId, quantity) {
  selectedProductId.value = productId;
  selectedProductQuantity.value = quantity;
  $("#restock-modal").modal("show");
}
function openRestockHistory(productId) {
  selectedProductId.value = productId;
  adminStore.fetchRestockHistories(productId);
  $("#restock-history-modal").modal("show");
}

async function updateSplashScreenStatus(userId) {
  await adminStore.updateSplashScreenStatus(userId);
  await adminStore.fetchSplashScreen();
}

onMounted(async () => {
  if (SplashScreen.value.data.length == 0) {
    await adminStore.fetchSplashScreen();
  }
});
</script>
