import Swal from "sweetalert2";
export function confirm({
  title,
  message,
  callback,
  confirmButtonText,
  cancelButtonText,
}) {
  // Use sweetalert2
  Swal.fire({
    title: title,
    text: message,
    showCancelButton: true,
    confirmButtonColor: "var(--PRIMARY-COLOR)",
    cancelButtonColor: "#d33",
    confirmButtonText: confirmButtonText || "Yes, Continue",
    cancelButtonText: cancelButtonText || "Cancel",
  }).then((result) => {
    callback(result.isConfirmed, result);
  });
}
