import { api } from "@/configs";
import { defineStore } from "pinia";
import { apiUrls } from "@/constants";

export const useProductStore = defineStore("product", {
  state: () => {
    return {
      products: [],
      AddOns: [],
      categories: [],
      vendors: [],
      orders: [],
      dispensedOrders: [],
      restockHistories: [],
      vendorOrderedProducts: [],
      filteredProducts: [],
      //   product: null,
    };
  },
  actions: {
    async fetchProducts() {
      try {
        const response = await api.get(apiUrls.products);
        this.products = response.data.data;
      } catch (error) {
        console.error(error);
      }
    },
    async updateProductStatus(id) {
      try {
        const response = await api.patch(
          `${apiUrls.products}/status/${id}`,
          // { _method: "PATCH", ...user },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        return true;
      } catch (error) {
        log(error);
        return false;
      }
    },

    async deleteProduct(id) {
      try {
        const response = await api.delete(`${apiUrls.products}/${id}`);

        this.fetchProducts();
        Swal.fire(
          type.charAt(0).toUpperCase() +
            string.slice(1) +
            " Product Deleted Successfully",
          "",
          "success"
        );
      } catch (error) {}
    },

    async createProduct(product) {
      try {
        const response = await api.post(apiUrls.products, product, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        this.fetchProducts();
        Swal.fire({
          title: "Product Added Successfully",
          icon: "success",
        });
        // commit("addProduct", response.data);
      } catch (error) {
        console.error(error);
      }
    },

    async updateProduct(id, product) {
      try {
        const response = await api.post(
          `${apiUrls.products}/${id}`,
          {
            ...product,
            _method: "PATCH",
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        this.fetchProducts();
        Swal.fire(
          type.charAt(0).toUpperCase() +
            string.slice(1) +
            " Product Updated Successfully",
          "",
          "success"
        );
        // commit("addProduct", response.data);
      } catch (error) {
        console.error(error);
      }
    },

    async fetchCategories() {
      try {
        const response = await api.get(`${apiUrls.products}/categories`);
        this.categories = response.data.data;
      } catch (error) {
        console.error(error);
      }
    },

    async addCategory(categories) {
      try {
        const response = await api.post(
          `${apiUrls.products}/categories`,
          categories
        );
        this.fetchCategories();
        Swal.fire(
          type.charAt(0).toUpperCase() +
            string.slice(1) +
            " Category Added Successfully",
          "success"
        );
        // commit("addProduct", response.data);
      } catch (error) {
        console.error(error);
      }
    },

    async updateCategory(id, categories) {
      try {
        const response = await api.patch(
          `${apiUrls.products}/categories/${id}`,
          categories
        );
        this.fetchCategories();
        Swal.fire(
          type.charAt(0).toUpperCase() +
            string.slice(1) +
            " Category Updated Successfully",
          "",
          "success"
        );
        // commit("addProduct", response.data);
      } catch (error) {
        console.error(error);
      }
    },

    async deleteCategory(id) {
      try {
        const response = await api.delete(
          `${apiUrls.products}/categories/${id}`
        );

        this.fetchCategories();
        this.fetchProducts();
        Swal.fire(
          type.charAt(0).toUpperCase() +
            string.slice(1) +
            " Category Deleted Successfully",
          "",
          "success"
        );
      } catch (error) {}
    },

    async fetchOrders(value) {
      try {
        // Format the query string using URLSearchParams
        const params = new URLSearchParams({ dispense: value }).toString();

        // Make the API call to fetch the orders
        const response = await api.get(`${apiUrls.products}/orders?${params}`);

        this.orders = response.data.data;
      } catch (error) {
        console.error(error);
      }
    },
    async fetchDispensedOrders(value) {
      try {
        // Format the query string using URLSearchParams
        const params = new URLSearchParams({ dispense: value }).toString();

        // Make the API call to fetch the orders
        const response = await api.get(`${apiUrls.products}/orders?${params}`);

        this.dispensedOrders = response.data.data; // For non-dispensed orders
      } catch (error) {
        console.error(error);
      }
    },

    async fetchVendors() {
      try {
        const response = await api.get(`${apiUrls.products}/vendors`);
        this.vendors = response.data.data;
      } catch (error) {
        console.error(error);
      }
    },

    async addVendor(vendors) {
      try {
        const response = await api.post(`${apiUrls.products}/vendors`, vendors);
        this.fetchVendors();
        Swal.fire({
          title: "Vendor Added Successfully",
          icon: "success",
        });
        return response.data;
        // commit("addProduct", response.data);
      } catch (error) {
        console.error(error);

        // Show error message to the user using SweetAlert
        Swal.fire({
          title: "Error Adding vendor",
          text:
            error.response?.data?.message ||
            "Something went wrong. Please try again.",
          icon: "error",
        });

        return false; // Indicate failure
      }
    },

    async updateVendor(id, vendor) {
      try {
        const response = await api.patch(
          `${apiUrls.products}/vendors/${id}`,
          vendor
        );
        // Optionally re-fetch the updated vendor list
        if (response.data.data.otpSent) {
          Swal.fire({
            title: "OTP sent successfully",
            icon: "success",
          });
        } else {
          this.fetchVendors();
          Swal.fire({
            title: "Vendor Updated Successfully",
            icon: "success",
          });
        }

        return response.data; // Indicate success
      } catch (error) {
        console.error(error);

        // Show error message to the user using SweetAlert
        Swal.fire({
          title: "Error updating vendor",
          text:
            error.response?.data?.message ||
            "Something went wrong. Please try again.",
          icon: "error",
        });

        return false; // Indicate failure
      }
    },

    async verifyUpdateVendorOtp(id, vendor) {
      try {
        const response = await api.patch(
          `${apiUrls.products}/vendors/verify/otp/${id}`,
          vendor
        );
        this.fetchVendors();
        Swal.fire({
          title: "Vendor Updated Successfully",
          icon: "success",
        });

        return response.data; // Indicate success
      } catch (error) {
        console.error(error);

        // Show error message to the user using SweetAlert
        Swal.fire({
          title: "Error updating vendor",
          text:
            error.response?.data?.message ||
            "Something went wrong. Please try again.",
          icon: "error",
        });

        return false; // Indicate failure
      }
    },

    async deleteVendor(id) {
      try {
        const response = await api.delete(`${apiUrls.products}/vendors/${id}`);

        this.fetchVendors();
        this.fetchProducts();
        Swal.fire({
          title: "Vendor Deleted Successfully",
          icon: "success",
        });
      } catch (error) {}
    },

    async fetchVendorOrderedProducts($vendorId) {
      try {
        const response = await api.get(
          `${apiUrls.products}/vendors/ordered/product/${$vendorId}`
        );
        this.vendorOrderedProducts = response.data.data;
      } catch (error) {
        console.error(error);
      }
    },

    async fetchRestockHistories(productId) {
      try {
        const response = await api.get(
          `${apiUrls.products}/restocks/${productId}`
        );
        this.restockHistories = response.data.data;
      } catch (error) {
        console.error(error);
      }
    },

    async restock(params) {
      try {
        const response = await api.post(`${apiUrls.products}/restocks`, params);
        this.fetchProducts();
        Swal.fire({
          title: "Product Restocked Successfully",
          icon: "success",
        });
        return response.data;
      } catch (error) {
        console.error(error);
        Swal.fire({
          title: "Restock Failed",
          text:
            error.response?.data?.message ||
            "An error occurred while restocking the product.",
          icon: "error",
        });
        return false;
      }
    },

    async sendVendorOtp(params) {
      try {
        const response = await api.post(
          `${apiUrls.products}/vendors/get/otp`,
          params
        );
        return response.data;
      } catch (error) {
        console.error(error);
        return false;
      }
    },

    async dispenseOrder(id) {
      try {
        const response = await api.post(
          `${apiUrls.products}/orders/dispense/${id}`
        );
        // this.fetchOrders()
        return response.data;
      } catch (error) {
        console.error(error);
        return false;
      }
    },

    async fetchAddOns() {
      try {
        const response = await api.get(`${apiUrls.products}/addons`);
        this.addOns = response.data.data;
      } catch (error) {
        console.error(error);
      }
    },

    async addAddon(addon) {
      try {
        const response = await api.post(`${apiUrls.products}/addons`, addon);
        this.fetchAddOns();
        Swal.fire({
          title: "Add-on Added Successfully",
          icon: "success",
        });
        return response.data.status;
      } catch (error) {
        console.error(error);
        return false
      }
    },

    async updateAddon(id, addon) {
      try {
        const response = await api.patch(`${apiUrls.products}/addons/${id}`, addon);
        this.fetchAddOns();
        Swal.fire({
          title: "Add-on Updated Successfully",
          icon: "success",
        });
        return response.data.status;
      } catch (error) {
        console.error(error);
        return false
      }
    },

    async deleteAddon(id) {
      try {
        const response = await api.delete(`${apiUrls.products}/addons/${id}`);
        this.fetchAddOns();
        Swal.fire({
          title: "Add-on Deleted Successfully",
          icon: "success",
        });
      } catch (error) {}
    },
  },
});
