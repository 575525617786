<template>
  <!-- Add Plan -->
  <div class="modal fade" id="voting-category-modal">
    <div class="modal-dialog modal-dialog-centered custom-modal-two">
      <div class="modal-content">
        <div class="page-wrapper-new p-0">
          <div class="content">
            <div class="modal-header border-0 custom-modal-header">
              <div class="page-title">
                <h4>Voting Category</h4>
                <!-- {{ votingCategory }} -->
              </div>
              <button
                type="button"
                class="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body custom-modal-body">
              <form @submit.prevent="submitForm">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="input-blocks">
                      <label>Category Name</label>
                      <input
                        type="text"
                        name="name"
                        required
                        v-model="votingCategory.name"
                        :class="{ 'form-control': true }"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="input-blocks">
                    <label>Description</label>
                    <textarea
                      name="description"
                      v-model="votingCategory.description"
                    ></textarea>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="input-blocks">
                    <label>Required Gender</label>
                    <vue-select
                      tabindex="1"
                      class="w-100"
                      id="users"
                      :options="required_gender"
                      required
                      name="user"
                      v-model="votingCategory.required_gender"
                    />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="input-blocks">
                    <label>Voting Starts</label>
                    <input
                      type="date"
                      name="start_day"
                      required
                      v-model="votingCategory.start_day"
                      :class="{ 'form-control': true }"
                    />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="input-blocks">
                    <label>Voting Ends</label>
                    <input
                      type="date"
                      name="last_day"
                      required
                      v-model="votingCategory.last_day"
                      :class="{ 'form-control': true }"
                    />
                  </div>
                </div>
                <div class="col-lg-12" v-if="votingCategory.required_gender === 'Both'">
                  <div class="input-blocks d-flex align-items-center">
                    <label for="old_member" class="mb-0">Two Winners?</label>
                    <a-switch
                      style="left: 310px"
                      :checked="votingCategory.two_winners"
                      @click="twoWinners()"
                    />
                  </div>
                </div>
                <!-- </div> -->

                <div class="row">
                  <div class="col-lg-12">
                    <div class="input-blocks">
                      <label>Banner</label>
                      <div class="custom-file">
                        <input
                          type="file"
                          id="file-input"
                          @change="previewImage"
                          accept="image/*"
                          class="custom-file-input"
                        />
                        <label for="file-input" class="custom-file-label"
                          >Choose Banner</label
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-12" v-if="imageUrl">
                    <div class="image-preview">
                      <img
                        :src="getImageUrl(imageUrl)"
                        alt="Image Preview"
                        class="img-thumbnail"
                      />
                      <button type="button" @click="removeImage" class="btn remove-btn">
                        ✖
                      </button>
                    </div>
                  </div>
                </div>

                <div class="modal-footer-btn">
                  <button
                    type="button"
                    class="btn btn-cancel me-2"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button type="submit" class="btn btn-submit" id="submit">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Plan -->
</template>
<script setup>
import { checkInputValidity } from "@/utils/validator";
import { computed, onMounted, reactive, ref, watch } from "vue";
import { useAdminStore } from "@/store/adminStore";
import { useVotingStore } from "@/store/VotingStore";
// stores
const votingStore = useVotingStore();

const props = defineProps({
  votingCategoryId: null,
});
const required_gender = ref(["Male", "Female", "Both"]);
// data
const initialCategoryState = {
  name: "",
  required_gender: "",
  two_winners: false,
  description: null,
  last_day: null,
  start_day: null,
  icon: null, // File object for the uploaded image
};

const votingCategory = reactive({ ...initialCategoryState });

const imageUrl = ref(null); // Reactive ref to store the preview URL

/**
 * Handles the image preview when a file is selected.
 * @param {Event} event - The file input change event.
 */
const previewImage = (event) => {
  const file = event.target.files[0]; // Get the first file from the input
  if (file) {
    imageUrl.value = URL.createObjectURL(file); // Generate a preview URL for the file
    votingCategory.icon = file; // Store the file in the votingCategory object
    console.log("Selected file:", votingCategory.icon);
  }
};

/**
 * Removes the currently selected or previewed image.
 */
const removeImage = () => {
  if (imageUrl.value) {
    URL.revokeObjectURL(imageUrl.value); // Release the URL
  }
  imageUrl.value = null; // Clear the preview URL
  votingCategory.icon = null; // Remove the file from votingCategory
};

/**
 * Retrieves the appropriate image URL.
 * - If the image is from the server, it will return its URL.
 * - If locally added, it will return the preview URL.
 * @param {Object} image - The image object (from server or local).
 * @returns {String|null} - The image URL or null if no image exists.
 */
const getImageUrl = (image) => {
  if (typeof image === "object" && image?.url) {
    return image.url; // Server-provided URL
  }
  return imageUrl.value; // Locally previewed URL
};

async function submitForm() {
  let response;
  if (props.votingCategoryId) {
    response = await votingStore.updateCategory(props.votingCategoryId, votingCategory);
    
  } else {
    // add
    response = await votingStore.addCategory(votingCategory);
    // await planStore.fetchGymList({type:plans.Gym})
  }
  if (response) {
    // Reset form fields
    Object.assign(votingCategory, initialCategoryState);
    imageUrl.value = null; // Clear the preview URL

    $("#voting-category-modal").modal("hide");
  }
}

function twoWinners() {
  votingCategory.two_winners = !votingCategory.two_winners;
}

watch(
  () => votingCategory,
  () => {
    // setTimeout is used here so the service will be passed to the input b4 this runs
    setTimeout(() => checkInputValidity("submit"), 5);
  },
  { deep: true }
);

watch(
  () => props.votingCategoryId,
  (newVotingCategoryIdId) => {
    if (newVotingCategoryIdId) {
      const existingVotingCategory = votingStore.votingCategories.find(
        (votingCategory) => votingCategory.id == newVotingCategoryIdId
      );
      if (existingVotingCategory) {
        Object.assign(votingCategory, existingVotingCategory);
        imageUrl.value = existingVotingCategory.icon;
      }
    } else {
      Object.assign(votingCategory, {
        name: "",
        required_gender: "",
      });
    }
  },
  { immediate: true }
);

onMounted(() => {});
</script>

<style scoped>
/* Styling for image previews */
.image-preview {
  position: relative;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
}

.image-preview img {
  max-width: 150px;
  max-height: 150px;
  border-radius: 8px;
}

.remove-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  color: rgb(247, 7, 7);
  border: none;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  cursor: pointer;
  font-size: 14px;
}

/* Custom file input styling */
.custom-file {
  position: relative;
  display: inline-block;
}

.custom-file-input {
  display: none; /* Hide the default file input */
}

.custom-file-label {
  display: inline-block;
  padding: 10px 20px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  background-color: #ffffff;
  color: #495057;
  cursor: pointer; /* Change cursor to pointer on hover */
  transition: background-color 0.3s, border-color 0.3s;
}

/* Hover and focus effects */
.custom-file-label:hover {
  background-color: #f8f9fa; /* Light gray on hover */
  border-color: #80bdff; /* Blue border on hover */
}

.custom-file-label:focus {
  outline: none; /* Remove outline on focus */
  border-color: #80bdff; /* Blue border on focus */
}
</style>
