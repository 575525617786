<template>
  <!-- Add user -->
  <div class="modal fade" id="vendor-product-modal">
    <div class="modal-dialog modal-dialog-centered custom-modal-two">
      <div class="modal-content">
        <div class="page-wrapper-new p-0">
          <div class="content">
            <div class="modal-header border-0 custom-modal-header">
              <div class="page-title">
                <h4 style="margin-left: 17px">
                  {{ vendor.name }}
                </h4>
              </div>
              <button
                type="button"
                class="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body custom-modal-body">
              <filter-table
                :extra-class="['table', 'datanew']"
                :columns="vendorProducts.columns"
                :data="vendorProducts.products"
              >
                <template #header>
                   <h4 style="margin-bottom: 15px">
                    Products
                  </h4>
                 <!-- <h4 style="margin-bottom: 15px">
                    Total Product Value: ₦{{ vendorProducts.products.reduce((sum, product) => sum + product.price, 0) }}
                  </h4> -->
                  <!-- <h4 style="float:right">{{props.selected_account_number}}</h4>   -->
                </template>
                {{ user }}
                <template #bodyCell="{ column, record }">
                  <template v-if="column.key === 'name'">
                    <div class="userimgname gap-2 align-items-center">
                      <div>
                        <a href="javascript:void(0);">{{ record.name }}</a>
                      </div>
                    </div>
                  </template>
                  <template v-else-if="column.key === 'status'">
                    <a-switch
                      :checked="record.is_active"
                      @change="updateUserStatus($event, record.id)"
                    />
                  </template>
                  <template v-if="column.key === 'account_number'">
                    <a
                      href="javascript:void(0);"
                      @click="openHistoryModal(record.account_number)"
                      style="color: red"
                      >{{ record.account_number }}</a
                    >
                  </template>
                </template>
              </filter-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Plan -->
</template>
<script setup>
import { useProductStore } from "@/store/productStore";
import { useUserStore } from "@/store/userStore";
import { checkInputValidity } from "@/utils/validator";
import { computed, onMounted, reactive, ref, watch } from "vue";
// stores
const vendorStore = useProductStore();

const vendorProducts = ref({
  filters: ["product"],
  columns: ref([
    {
      title: "Product",
      dataIndex: "name",
      key: "name",
      sorter: {
        compare: (a, b) => {
          a = a.name.toLowerCase();
          b = b.name.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Vendor Price",
      dataIndex: "vendor_price",
      sorter: {
        compare: (a, b) => {
          a = a.vendor_price.toLowerCase();
          b = b.vendor_price.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Selling Price",
      dataIndex: "price",
      sorter: {
        compare: (a, b) => {
          a = a.price.toLowerCase();
          b = b.price.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Quantity",
      dataIndex: "available_quantity",
      sorter: {
        compare: (a, b) => {
          a = a.purpose.toLowerCase();
          b = b.purpose.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Admin",
      dataIndex: "admin_name",
      key: "admin_name",
      sorter: {
        compare: (a, b) => {
          a = a.admin_name.toLowerCase();
          b = b.admin_name.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      sorter: {
        compare: (a, b) => {
          a = a.created_at.toLowerCase();
          b = b.created_at.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    // {
    //   title: "Action",
    //   key: "action",
    //   sorter: true,
    // },
  ]),
  products: computed(() => {
    return vendorStore.products.filter((product) => product.vendor_id === props.vendorId);
  }),

  selectedUserId: ref(null),
});

const props = defineProps({
  vendorId: null,
  selected_name: null,
  action: null,
});
// data
const vendor = ref({
  name: null,
});

//   watch(
//     () => vendor,
//     () => {
//       // setTimeout is used here so the user will be passed to the input b4 this runs
//       setTimeout(() => checkInputValidity("submit"), 5);
//     },
//     { deep: true }
//   );

watch(
  () => props.vendorId,
  () => {
    if (props.vendorId) {
      vendor.value = vendorStore.vendors.find((vendor) => vendor.id == props.vendorId);
    } else {
      vendor.value = {};
    }
  },
  { immediate: true }
);

onMounted(async () => {
  // await userStore.fetchUserTransactionHistory(props.account_number);
});
</script>

<style scoped>
#transaction-history-modal {
  width: 100%;
}
</style>
