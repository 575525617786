<template>
  <!-- Add Plan -->
  <div class="modal fade" id="plan-modal">
    <div class="modal-dialog modal-dialog-centered custom-modal-two">
      <div class="modal-content">
        <div class="page-wrapper-new p-0">
          <div class="content">
            <div class="modal-header border-0 custom-modal-header">
              <div class="page-title">
                <h4>Product</h4>
                <!-- {{ products }} -->
              </div>
              <button
                type="button"
                class="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body custom-modal-body">
              <form @submit.prevent="submitForm">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="input-blocks">
                      <label>Product Name</label>
                      <input
                        type="text"
                        name="name"
                        required
                        v-model="products.name"
                        :class="{ 'form-control': true }"
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="input-blocks">
                      <label>Product Size</label>
                      <input
                        type="text"
                        name="size"
                        required
                        v-model="products.size"
                        :class="{ 'form-control': true }"
                      />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-6">
                    <div class="input-blocks">
                      <label>Price</label>
                      <input
                        type="number"
                        name="price"
                        required
                        v-model="products.price"
                        :class="{ 'form-control': true }"
                      />
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="input-blocks">
                      <label>Vendor Price</label>
                      <input
                        type="number"
                        name="size"
                        required
                        v-model="products.vendor_price"
                        :class="{ 'form-control': true }"
                      />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-6">
                    <div class="input-blocks">
                      <label>Quantity</label>
                      <input
                        :disabled="props.productId"
                        type="number"
                        name="quantity"
                        required
                        v-model="products.available_quantity"
                        :class="{ 'form-control': true }"
                      />
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="input-blocks">
                      <label>Category</label>
                      <vue-select
                        :options="categories"
                        placeholder="Choose"
                        v-model="products.category_id"
                      />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-12">
                    <div class="input-blocks">
                      <label>Vendor</label>
                      <vue-select
                        :options="vendors"
                        placeholder="Choose"
                        v-model="products.vendor_id"
                      />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-12">
                    <div class="input-blocks">
                      <label>Description</label>
                      <textarea
                        name="description"
                        required
                        v-model="products.description"
                        :class="{ 'form-control': true }"
                        rows="4"
                      ></textarea>
                    </div>
                  </div>
                </div>

                <!-- Image Upload Section -->
                <div class="row">
                  <div class="col-lg-12">
                    <div class="input-blocks">
                      <label>Product Images</label>
                      <div class="custom-file">
                        <input
                          type="file"
                          id="file-input"
                          @change="previewImages"
                          accept="image/*"
                          multiple
                          class="custom-file-input"
                        />
                        <label for="file-input" class="custom-file-label"
                          >Choose Images</label
                        >
                      </div>
                    </div>
                  </div>

                  <!-- Image Previews -->
                  <!-- <div class="col-lg-12" v-if="imageUrls.length">
                    <div
                      v-for="(image, index) in imageUrls"
                      :key="index"
                      class="image-preview"
                    >
                      <img
                        :src="image"
                        alt="Image Preview"
                        class="img-thumbnail"
                        style="max-width: 150px; margin-top: 10px"
                      />
                      <button
                        type="button"
                        @click="removeImage(index)"
                        class="btn remove-btn"
                      >
                        ✖
                      </button>
                    </div>
                  </div> -->
                  <div class="col-lg-12" v-if="imageUrls.length">
                    <div
                      v-for="(image, index) in imageUrls"
                      :key="index"
                      class="image-preview"
                    >
                      <img
                        :src="getImageUrl(image)"
                        alt="Image Preview"
                        class="img-thumbnail"
                      />
                      <button
                        type="button"
                        @click="removeImage(index)"
                        class="btn remove-btn"
                      >
                        ✖
                      </button>
                    </div>
                  </div>
                </div>

                <div class="modal-footer-btn">
                  <button
                    type="button"
                    class="btn btn-cancel me-2"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button type="submit" class="btn btn-submit" id="submit">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Plan -->
</template>
<script setup>
import { checkInputValidity } from "@/utils/validator";
import { computed, onMounted, reactive, ref, watch } from "vue";
import { useProductStore } from "@/store/productStore";
// stores
const productStore = useProductStore();

const props = defineProps({
  productId: null,
});
// data
const products = reactive({
  name: "",
  price: "",
  category_id: "",
  vendor_id: "",
  description: "",
  available_quantity: "",
  size: "",
  images: [],
  description: "",
  vendor_price: "",
});
const imageUrls = ref([]);

const previewImages = (event) => {
  const files = Array.from(event.target.files);
  // Append new files to existing images

  files.map((file) => {
    imageUrls.value.push(URL.createObjectURL(file));
    products.images.push(file);
    console.log(file);
  });
};

const removeImage = (index) => {
  imageUrls.value.splice(index, 1);
  products.images.splice(index, 1);
};

const combinedImages = computed(() => [...imageUrls.value]);

const getImageUrl = (image) => {
  // Check if image is from the server or locally added
  return typeof image === "object" && image.url ? image.url : image;
};
async function submitForm() {
  if (props.productId) {
    // update
    // alert(props.productId);
    await productStore.updateProduct(props.productId, products);
  } else {
    // add
    await productStore.createProduct(products);
    // await planStore.fetchGymList({type:plans.Gym})
  }
  $("#plan-modal").modal("hide");
}

const categories = computed(() => {
  return productStore.categories.map((category) => {
    return { text: category.name, id: category.id };
  });
});
const vendors = computed(() => {
  return productStore.vendors.map((vendor) => {
    return { text: vendor.name, id: vendor.id };
  });
});

watch(
  () => products,
  () => {
    // setTimeout is used here so the service will be passed to the input b4 this runs
    setTimeout(() => checkInputValidity("submit"), 5);
  },
  { deep: true }
);

watch(
  () => props.productId,
  (newServiceId) => {
    imageUrls.value = [];
    if (props.productId) {
      const existingProduct = productStore.products.find(
        (product) => product.id == newServiceId
      );
      if (existingProduct) {
        Object.assign(products, existingProduct);
        products.images = [];
        // products.images = existingProduct.images;
        products.category_id = existingProduct.category.id;
        imageUrls.value = existingProduct.images;
      }
    } else {
      Object.assign(products, {
        name: "",
        price: "",
        category_id: "",
        description: "",
        available_quantity: "",
        size: "",
        images: [],
      });
    }
  },
  { immediate: true }
);

onMounted(() => {});
</script>

<style scoped>
/* Styling for image previews */
.image-preview {
  position: relative;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
}

.image-preview img {
  max-width: 150px;
  max-height: 150px;
  border-radius: 8px;
}

.remove-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  color: rgb(247, 7, 7);
  border: none;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  cursor: pointer;
  font-size: 14px;
}

/* Custom file input styling */
.custom-file {
  position: relative;
  display: inline-block;
}

.custom-file-input {
  display: none; /* Hide the default file input */
}

.custom-file-label {
  display: inline-block;
  padding: 10px 20px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  background-color: #ffffff;
  color: #495057;
  cursor: pointer; /* Change cursor to pointer on hover */
  transition: background-color 0.3s, border-color 0.3s;
}

/* Hover and focus effects */
.custom-file-label:hover {
  background-color: #f8f9fa; /* Light gray on hover */
  border-color: #80bdff; /* Blue border on hover */
}

.custom-file-label:focus {
  outline: none; /* Remove outline on focus */
  border-color: #80bdff; /* Blue border on focus */
}
</style>
