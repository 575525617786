<template>
    <div class="custom-modal" v-if="isOpen" @click="onBackgroundClick">
        <div class="modal-content" @click.stop>
            <div class="page-wrapper-new p-0">
                <div class="">
                    <div class="modal-header border-0 custom-modal-header">
                        <div class="page-title">
                            <h4>{{ title }}</h4>
                        </div>
                        <button type="button" class="close" aria-label="Close" @click="closeModal">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body custom-modal-body">
                        <div>
                            <slot></slot>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, defineExpose } from 'vue';

const emit = defineEmits(['opened', 'closed']);
const props = defineProps({
    title: {
        type: String,
        required: true,
    },
});
const isOpen = ref(false);

const openModal = () => {
    isOpen.value = true;
    emit('opened');
}

const closeModal = () => {
    isOpen.value = false;
    emit('closed');
}

const onBackgroundClick = (event) => {
    if (event.target.classList.contains('custom-modal')) {
        closeModal();
    }
}


// Expose methods to be accessible from the parent component
defineExpose({ openModal, closeModal });
</script>

<style scoped>
/* Modal container */
.custom-modal {
    position: fixed;
    z-index: 1005;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    animation: fadeIn 0.3s;
    overflow: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/* Modal content */
.modal-content {
    overflow: hidden;
    background-color: #fff;
    border: 1px solid #888;
    width: 100%;
    max-width: 500px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    position: relative;
    animation: slideIn 0.3s;
    max-height: calc(100% - 100px);
        /* Adjust as needed */
        overflow-y: auto;
}

@keyframes slideIn {
    from {
        transform: translateY(-20px);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}
</style>
