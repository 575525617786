<template>
  <!-- Add Plan -->
  <Modal
    title="Add Nominee"
    ref="nomineeModal"
    @opened="initData"
    id="voting-nominee-modal"
  >
    <div>
      <div class="row">
        <div class="col-lg-12">
          <div class="input-blocks">
            <label>Nominee</label>
            <vue-select
              tabindex="1"
              class="w-100"
              id="users"
              :options="users"
              required
              name="user"
              v-model="nominee.user_id"
            />
          </div>
          <div class="col-lg-12">
            <div class="input-blocks">
              <label>Voting Category</label>
              <vue-select
                tabindex="1"
                class="w-100"
                id="categories"
                :options="categories"
                required
                name="category"
                v-model="nominee.category_id"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer-btn">
        <button
          type="button"
          class="btn btn-cancel me-2"
          @click="nomineeModal.closeModal()"
        >
          Cancel
        </button>
        <button type="submit" class="btn btn-submit" id="submit" @click="submitForm()">
          Submit
        </button>
      </div>
    </div>
  </Modal>
  <!-- /Add Plan -->
</template>
<script setup>
import { checkInputValidity } from "@/utils/validator";
import { computed, onMounted, reactive, ref, watch } from "vue";
import { useAdminStore } from "@/store/adminStore";
import { useVotingStore } from "@/store/VotingStore";
import { useUserStore } from "@/store/userStore";
import Modal from "@/components/modal/modal.vue";
// stores
const votingStore = useVotingStore();
const userStore = useUserStore();
const nomineeModal = ref(null);
const props = defineProps({
  votingCategoryId: null,
});
const required_gender = ref(["Male", "Female", "Both"]);
// data

const initialNomineeState = {
  user_id: "",
  category_id: "",
};

const nominee = reactive({ ...initialNomineeState });

async function submitForm() {
  let response;
  if (props.votingCategoryId) {
    response = await votingStore
      .updateCategory(props.votingCategoryId, votingCategory)
      .then(() => nomineeModal.value.closeModal());
  } else {
    response = await votingStore
      .addNominees(nominee)
      .then(() => nomineeModal.value.closeModal());
  }
  if (response) {
    Object.assign(nominee, initialNomineeState);
    $("#voting-nominee-modal").modal("hide");
  }
}

function twoWinners() {
  votingCategory.two_winners = !votingCategory.two_winners;
}

watch(
  () => nominee,
  () => {
    // setTimeout is used here so the service will be passed to the input b4 this runs
    setTimeout(() => checkInputValidity("submit"), 5);
  },
  { deep: true }
);

watch(
  () => props.nomineeId,
  (newNomineeIdId) => {
    if (newNomineeIdId) {
      const existingNominee = votingStore.votingCategories.find(
        (nominee) => nominee.id == newNomineeIdId
      );
      if (existingNominee) {
        Object.assign(Nominee, existingNominee);
      }
    } else {
      Object.assign(nominee, {
        nominee_id: "",
        category_id: "",
      });
    }
  },
  { immediate: true }
);

const categories = computed(() => {
  return votingStore.votingCategories
    .filter((category) => category.is_active)
    .map((category) => ({
      text: category.name, // The label to display
      id: category.id, // The value to bind
    }));
});
// const users = computed(() => {
//   return userStore.users.map((user) => {
//     return { text: user.name, id: user.id };
//   });
// });

const users = computed(() =>
  userStore.users.map((user) => ({
    text: user.name,
    id: user.id,
  }))
);

defineExpose({ openModal: () => nomineeModal.value.openModal() });
onMounted(() => {});
</script>

<style scoped></style>
