<template>
    <!-- Add Plan -->
    <div class="modal fade" id="plan-modal">
        <div class="modal-dialog modal-dialog-centered custom-modal-two">
            <div class="modal-content">
                <div class="page-wrapper-new p-0">
                    <div class="content">
                        <div class="modal-header border-0 custom-modal-header">
                            <div class="page-title">
                                <h4>Add Subscription Plan</h4>
                            </div>
                            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body custom-modal-body">
                            <form @submit.prevent="submitForm">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="input-blocks">
                                            <label>Plan Name</label>
                                            <input type="text" name="name" required v-model="plan.name"
                                                :class="{ 'form-control': true }" />
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="input-blocks">
                                            <label>Price</label>
                                            <input type="number" name="price" required v-model="plan.price"
                                                :class="{ 'form-control': true }" />

                                        </div>
                                    </div>

                                    <div class="col-lg-6">
                                        <div class="input-blocks">
                                            <label>Duration (Months)</label>
                                            <input :min="0" type="number" name="months" required v-model="plan.months"
                                                :class="{ 'form-control': true }" />
                                        </div>
                                    </div>

                                </div>
                                <div class="modal-footer-btn">
                                    <button type="button" class="btn btn-cancel me-2" data-bs-dismiss="modal">
                                        Cancel
                                    </button>
                                    <button type="submit" class="btn btn-submit" id="submit">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- /Add Plan -->


</template>
<script setup>
import { usePlanStore } from '@/store/planStore';
import { checkInputValidity } from '@/utils/validator';
import { computed, onMounted, reactive, ref, watch } from 'vue';
// stores
const planStore = usePlanStore();

const props = defineProps({
    planId: null
});
// data
const plan = ref({
    name: '',
    price: null,
    months: 1,
});


async function submitForm() {
    if (props.planId) {
        // update
        await planStore.updateSubscriptionPlans(props.planId, plan.value);
    } else {
        // add
        await planStore.addSubscriptionPlans(plan.value);
    }
    $("#plan-modal").modal("hide");
}

watch(() => plan, () => {
    // setTimeout is used here so the plan will be passed to the input b4 this runs
    setTimeout(() => (
        checkInputValidity("submit")), 5)
}, { deep: true });

watch(() => props.planId, () => {
    if (props.planId) {
        plan.value = planStore.subscriptionPlans.find(plan => plan.id == props.planId);
    } else {
        plan.value = {};
    }
}, { immediate: true });

onMounted(() => {

});
</script>
