import { createApp } from "vue";
import { router } from "@/router";
import App from "@/App.vue";
import { BootstrapVue3, BToastPlugin } from "bootstrap-vue-3";
import Vue3Autocounter from "vue3-autocounter";
import VueApexCharts from "vue3-apexcharts";
import VueSelect from "vue3-select2-component";
import DatePicker from "vue3-datepicker";
import StarRating from "vue-star-rating";
import Antd from "ant-design-vue";
import VueFeather from "vue-feather";
import "ant-design-vue/dist/reset.css";
import FlagIcon from "vue-flag-icon";
import VueSweetalert2 from "vue-sweetalert2";
import VueFormWizard from "vue3-form-wizard";
import VueEasyLightbox from "vue-easy-lightbox";

import loader from "@/components/loader.vue";

/********* Icons **********/
import SimpleLineIcons from "vue-simple-line";

/********* Layout component**********/
import Header from "@/views/layouts/header.vue";
import Sidebar from "@/views/layouts/sidebar.vue";
import UserMenu from "@/views/layouts/usermenu.vue";
import FilesSidebar from "@/views/layouts/files-sidebar.vue";
import Settings_Sidebar from "@/views/layouts/settings-sidebar.vue";
import Collapsed_Sidebar from "@/views/layouts/collapsed-sidebar.vue";
import Horizontal_Sidebar from "@/views/layouts/horizontal-sidebar.vue";
import SideSettings from "@/views/layouts/side-settings.vue";

/********* Breadcrumb component**********/
import Breadcrumb from "@/components/breadcrumb/breadcrumb.vue";

/********* Page component**********/

/********* Modal component**********/

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap/dist/css/bootstrap.css";
import "@fortawesome/fontawesome-free/css/fontawesome.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "boxicons/css/boxicons.min.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "pe7-icon/dist/dist/pe-icon-7-stroke.css";
import "typicons.font/src/font/typicons.css";
import "weathericons/css/weather-icons.css";
import "ionicons-npm/css/ionicons.css";
import "@/assets/css/feather.css";
import "@/assets/css/style.css";

// import "@/assets/summernote/summernote.css";
// import "@/assets/summernote/summernote.js";

import swal from "sweetalert2";

import { VueQueryPlugin } from "@tanstack/vue-query";
import { createPinia } from "pinia";
import FilterTable from "./components/table/filter-table.vue";
import Avatar from "./components/avatar.vue";
import UpdatePage from "./views/pages/updates/update-page.vue";
import helpers from "./plugins/helpers";
window.Swal = swal;

const app = createApp(App);

app.component("update-page", UpdatePage);
app.component("loader", loader);
/********* Images ***********/
app.component("avatar", Avatar);

/********* Icons **********/
app.component("icon", SimpleLineIcons);
/********* Tables **********/
app.component("filter-table", FilterTable);
/********* Layout component**********/
app.component("layout-header", Header);
app.component("layout-sidebar", Sidebar);
app.component("user-menu", UserMenu);
app.component("files-sidebar", FilesSidebar);
app.component("settings-sidebar", Settings_Sidebar);
app.component("collapsed-sidebar", Collapsed_Sidebar);
app.component("horizontal-sidebar", Horizontal_Sidebar);
app.component("side-settings", SideSettings);

/********* Breadcrumb component**********/
app.component("breadcrumb", Breadcrumb);

/********* Modal component**********/

app.component("vue3-autocounter", Vue3Autocounter);
app.component(VueFeather.name, VueFeather);
app.component("vue-select", VueSelect);
app.component("datepicker", DatePicker);
app.component("star-rating", StarRating);
app.use(FlagIcon).use(VueFormWizard);
app.use(VueSweetalert2);
app.use(VueApexCharts);
app.use(VueEasyLightbox);

app.use(createPinia());
app.use(helpers);

app.use(Antd).use(BootstrapVue3).use(BToastPlugin);

app.use(VueQueryPlugin);
app.use(router);

app.mount("#app");
