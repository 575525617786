<template>
  <!-- Add period -->
  <div class="modal fade" id="plan-modal">
    <div class="modal-dialog modal-dialog-centered custom-modal-two">
      <div class="modal-content">
        <div class="page-wrapper-new p-0">
          <div class="content">
            <div class="modal-header border-0 custom-modal-header">
              <div class="page-title">
                <h4>Gym</h4>
              </div>
              <button
                type="button"
                class="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body custom-modal-body">
              <form @submit.prevent="submitForm">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="input-blocks">
                      <label>Name</label>
                      <input
                        type="text"
                        name="name"
                        required
                        v-model="period.name"
                        :class="{ 'form-control': true }"
                      />
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="input-blocks">
                      <label>Duration</label>
                      <input
                        type="number"
                        name="duration"
                        required
                        v-model="period.duration"
                        :class="{ 'form-control': true }"
                      />
                    </div>
                  </div>

                  <div class="col-lg-12">
                    <div class="input-blocks">
                      <label>Add-Ons</label>
                      <select
                        multiple
                        name="addOnId"
                        v-model="period.addOnId"
                        class="form-control"
                      >
                        <option
                          v-for="addOn in addOns"
                          :key="addOn.id"
                          :value="addOn.id"
                        >
                          {{ addOn.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="modal-footer-btn">
                  <button
                    type="button"
                    class="btn btn-cancel me-2"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button type="submit" class="btn btn-submit" id="submit">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Plan -->
</template>
<script setup>
import { usePlanStore } from "@/store/planStore";
import { checkInputValidity } from "@/utils/validator";
import { computed, onMounted, reactive, ref, watch } from "vue";
import { plans } from "@/constants/enum";
// stores
const planStore = usePlanStore();

const props = defineProps({
  periodId: null,
});
// data
const period = reactive({
  name: "",
  duration: "",
});

async function submitForm() {
  if (props.periodId) {
    // update
    await planStore.updatePeriod(props.periodId, period, plans.Gym);
  } else {
    // add
    await planStore.addPeriods(period, plans.Gym);
  }
  $("#plan-modal").modal("hide");
}

watch(
  () => period,
  () => {
    // setTimeout is used here so the plan will be passed to the input b4 this runs
    setTimeout(() => checkInputValidity("submit"), 5);
  },
  { deep: true }
);

watch(
  () => props.periodId,
  (newPeriodId) => {
    if (props.periodId) {
      const existingPeriod = planStore.GymPeriods.find(
        (period) => period.id == newPeriodId
      );
      if (existingPeriod) {
        Object.assign(period, existingPeriod);
      }
    } else {
      period.name = "";
      period.duration = "";
    }
  },
  { immediate: true }
);

onMounted(() => {});
</script>
